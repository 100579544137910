import React, { useEffect, useState } from 'react';
import logoNew from '~/assets/images/logo_new.png';

function getCurrentFormattedDate() {
    const currentDate = new Date();

    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());

    return `${day}/${month}/${year}`;
}

function ListHome(drops) {
    const [random1s, setRandom1s] = useState(true);

    let amount = drops.amount;
    let level = ['VIP1', 'VIP2', 'VIP3', 'VIP4', 'VIP5', 'VIP6'];
    let numberPhone = ['036', '034', '094', '092', '096', '078', '085', '093', '092', '091', '038', '037', '039'];

    function random(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function name() {
        return numberPhone[random(0, 11)] + '****' + random(1000, 9999);
    }

    function formatMoney(money = 0) {
        return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    useEffect(() => {
        // Lưu reference đến interval trong một biến
        const interval = setInterval(() => {
            setRandom1s((prev) => !prev);
        }, 5000);

        // Xóa interval khi component unmount (hủy)
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <ul className="pl-4">
            {Array(amount)
                .fill({})
                .map((data, index) => {
                    return (
                        <li className="flex justify-around items-center pt-[30px] item-listhome__style" key={index}>
                            <div className="flex items-center border border-dashed p-2">
                                <img className="md:max-w-[150px]" src={logoNew} alt="img" width={60} />
                                {/* <p className="text-xl ml-[5px] md:text-2xl text-[#005652]">{level[random(0, 5)]}</p> */}
                            </div>
                            <div className="flex-col items-center justify-center">
                                <p className="text-xl ml-[5px] md:text-2xl text-[black] font-bold">{name()}</p>
                                <p className="text-xl ml-[5px] md:text-2xl text-[black] pt-1">
                                    {getCurrentFormattedDate()}
                                </p>
                            </div>
                            <div className="flex items-center bg-green-500 p-2 rounded-2xl">
                                <p className="min-w-[25px] text-xl md:text-2xl text-[white] font-medium">
                                    +{formatMoney(random(1000000, 30000000))} VNĐ
                                </p>
                            </div>
                        </li>
                    );
                })}
        </ul>
    );
}

export default ListHome;
