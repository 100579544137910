import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './Login.module.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Button, Input } from 'reactstrap';
import gg_fb from '../../assets/images/logo_gg_fbs.png';
import headerBG from '../../assets/images/header-bg.png';
import logo from '../../assets/images/logo.png';
import Account from './index';
import '../../style/login.css';
import Footer from '../../components/Footer/footer';
import backgroundFooter from '../../assets/images/bg-artist.png';
import logoNew from '../../assets/images/logo_new.png';
import phone from '../../assets/images/phone.png';
import lock from '../../assets/images/lock.png';
import iconLogin from '../../assets/images/icon_login.png';

const domain = process.env.REACT_APP_DOMAIN;
const axios = require('axios').default;

const cx = classNames.bind(styles);

function Login(props) {
    const [Lock, setLock] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const path = window.location.pathname;

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        // Cập nhật thời gian mỗi giây
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        // Xóa interval khi component bị unmount
        return () => clearInterval(interval);
    }, []);

    const day = currentTime.getDate().toString().padStart(2, '0');
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0');
    const year = currentTime.getFullYear().toString();
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');

    // Định dạng hiển thị thời gian theo yêu cầu
    const formattedTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    useEffect(() => {
        document.title = props.title;
    }, []);

    const checkLock = () => {
        setLock(!Lock);
    };

    const handleLogin = (datas) => {
        let { username, password } = datas;
        let pattern = /^[0-9]*\d$/;
        if (!username || !password) return toast.warn('Vui lòng nhập đầy đủ thông tin', { theme: 'light' });
        if (!pattern.test(username)) return toast.warn('Tài khoản không đúng định dạng!', { theme: 'light' });

        axios
            .post(`${domain}/api/webapi/login`, {
                username: username,
                password: password,
            })
            .then(function (response) {
                let status = response.data.status;
                localStorage.setItem('id', response.data.id);
                if (status === 'ok') {
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1500);
                    localStorage.setItem('auth', response.data.auth);
                    return toast.success(response.data.message, { theme: 'light' });
                }
                return toast.error(response.data.message, { theme: 'light' });
            })
            .catch(function (error) {
                console.log(error);
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    return (
        <div className="yt-main-content">
            <div className="yt-main-content-wrap yt-account-tabs">
                <div className="account-tab-header">
                    <img src={logoNew} alt="" />

                    <div className="count_time">{formattedTime}</div>
                </div>
            </div>

            <div className="fs-1 font-bold p-8">Đăng nhập tài khoản</div>

            <div className="input-login">
                <Input
                    onInput={(e) => {
                        setUsername(e.target.value);
                    }}
                    type="number"
                    placeholder="Số điện thoại"
                    autoComplete="off"
                    maxLength="128"
                    spellCheck="false"
                    bsSize="lg"
                    className="mb-3"
                />

                <img src={phone} alt="" />
            </div>

            <div className="input-login">
                <Input
                    onInput={(e) => {
                        setPassword(e.target.value);
                    }}
                    type={Lock ? 'text' : 'password'}
                    placeholder="Mật khẩu"
                    autoComplete="new-password"
                    maxLength="128"
                    bsSize="lg"
                    className="mb-3"
                ></Input>
                <img src={lock} alt="" />
                <FontAwesomeIcon onClick={checkLock} className="svg-pass" icon={Lock ? faLockOpen : faLock} />
            </div>

            <div className="button-login">
                <Button type="button" onClick={() => handleLogin({ username, password })}>
                    ĐĂNG NHẬP
                    <img src={iconLogin} alt="" />
                </Button>
            </div>
            <div className="support-pass">
                <Link to="/account/register" className="fs-3">
                    Đăng ký tài khoản
                </Link>
            </div>

            <div className="background_footer">
                <img src={backgroundFooter} alt="" />
            </div>

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Login;
