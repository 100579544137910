import { configureStore } from '@reduxjs/toolkit';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { pageApi, roomTicketApi, userApi } from '../features';

const middleware = [...getDefaultMiddleware(), roomTicketApi.middleware, pageApi.middleware, userApi.middleware];

export const store = configureStore({
    reducer: {
        [pageApi.reducerPath]: pageApi.reducer,
        [roomTicketApi.reducerPath]: roomTicketApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
    },
    middleware,
});
