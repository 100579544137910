export const headers = () => ({
    'x-access-token': localStorage.getItem('auth'),
    'Access-Control-Allow-Origin': '*',
});

export const checkToken = ({ status, message }) => {
    if (status === 'error' && (message === 'Invalid token' || message === 'Access denied!')) {
        // window.location.href = '/account/login';
        window.location.assign('/account/login');
        sessionStorage.removeItem('auth');
    }
};
