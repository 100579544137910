import bgImg from '~/assets/images/background-my.jpg';
import { formatMoney } from '~/utils/helper';

const domain = process.env.REACT_APP_DOMAIN;

const RoomLevel = ({ data, setActiveTab, activeTab }) => {
    return (
        <div className="grid grid-cols-2 gap-4 p-4">
            {data.map((item) => (
                <div
                    key={item.id}
                    className={`relative h-[220px]${activeTab === item.id_level ? ' active' : ''}`}
                    style={{ background: `url(${bgImg})` }}
                    onClick={() => setActiveTab(item.id_level)}
                    id="room-ticket-card"
                >
                    <div className="absolute top-0 left-0 z-10 flex flex-col items-center w-full">
                        <img
                            src={domain + '/static/media/' + item.id_level + '_icon.png'}
                            className="rounded-full p-4 w-40"
                            alt=""
                        />
                        <span className="text-3xl text-white">Lựa chọn gói</span>
                        <span className="text-3xl text-orange-500">{formatMoney(item.amount)}</span>
                        <span className="text-3xl text-white mt-4">Lợi nhuận</span>
                        <span className="text-3xl text-orange-500">{item.roses}%/ngày</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RoomLevel;
