import React from 'react';
import './newsrun.scss';
import bellNotification from '~/assets/images/Bell_Notification.png';

function NewRun() {
    return (
        <div className="home-news-run mt-12">
            <div className="wrap-run flex items-center justify-center">
                <h3 className="title ml-2 pt-2 pr-2">
                    <img src={bellNotification} alt="" width={20} />
                </h3>
                <div className="wrapper">
                    <p className="target">
                        Nhân dịp thành lập 10 năm của Công ty TNHH Ticketbox Việt Nam, khi bạn mở phòng bán vé trên hệ
                        thống của chúng tôi, bạn sẽ có cơ hội nhận được những phần thưởng hấp dẫn từ hệ thống, đặc biệt
                        là 1 Xe ô tô Toyota Vios 1.5E CVT 2023 trị giá 542.000.000 VND dành cho người may mắn nhất, nhằm
                        cảm ơn bạn đã đồng hành và phát triển cùng công ty chúng tôi trong suốt thời gian vừa qua. Xin
                        cảm ơn và chúc bạn may mắn!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default NewRun;
