import { toast } from 'react-toastify';

export const messageCreated = () =>
    toast.loading('Vui lòng chờ ...', { isLoading: true });

export const messageUpdate = ({ id, status, msg }) => {
    if (status === true || status === 'ok') {
        toast.update(id, {
            render: msg,
            type: 'success',
            isLoading: false,
            autoClose: 2000,
        });
    } else if (status === false) {
        toast.update(id, {
            render: msg,
            type: 'warning',
            autoClose: 2000,
            isLoading: false,
        });
    } else {
        toast.update(id, {
            render: msg,
            type: 'error',
            autoClose: 5000,
            isLoading: false,
        });
    }
};
