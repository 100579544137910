import home from '../../../../assets/images/home_icon.png';
import home2 from '../../../../assets/images/home_icon_active.png';
import mission from '../../../../assets/images/ticket_icon.png';
import mission2 from '../../../../assets/images/ticket_icon_active.png';
import my from '../../../../assets/images/ho_so.png';
import my2 from '../../../../assets/images/ho_so_active.png';
import cophieu from '../../../../assets/images/cophieu.png';
import cophieuActive from '../../../../assets/images/cophieuActive.png';
import navHistory from '../../../../assets/images/history_icon.png';
import navHistoryActive from '../../../../assets/images/history_icon_active.png';
import navSupport from '../../../../assets/images/tin_tuc.png';
import navSupportActive from '../../../../assets/images/tin_tuc_active.png';
import { Link, Outlet } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import '../../../../style/navbar.css';

function Navbar(props) {
    let link = props.link;
    let [homeN, setHome] = useState(link === '/' ? true : false);
    let [orderN, setOrder] = useState(link === '/order/mission' ? true : false);
    let [myN, setMy] = useState(link === '/my' ? true : false);
    let [history, setHistory] = useState(link === '/order/index' ? true : false);
    let [support, setSupport] = useState(link === '/support' ? true : false);
    let [roomtickets, setRoomtickets] = useState(link === '/roomtickets' ? true : false);

    useEffect(() => {
        if (link.indexOf('/order/mission') >= 0) {
            setHome(false);
            setOrder(true);
            setMy(false);
            setHistory(false);
            setRoomtickets(false);
            setSupport(false);
        } else if (link === '/') {
            setHome(true);
            setOrder(false);
            setMy(false);
            setHistory(false);
            setRoomtickets(false);
            setSupport(false);
        } else if (link === '/my') {
            setHome(false);
            setOrder(false);
            setMy(true);
            setHistory(false);
            setRoomtickets(false);
            setSupport(false);
        } else if (link === '/order/index') {
            setHome(false);
            setOrder(false);
            setMy(false);
            setHistory(true);
            setRoomtickets(false);
            setSupport(false);
        } else if (link === '/news') {
            setHome(false);
            setOrder(false);
            setMy(false);
            setHistory(false);
            setSupport(true);
            setRoomtickets(false);
        } else if (link === '/roomtickets') {
            setHome(false);
            setOrder(false);
            setMy(false);
            setHistory(false);
            setSupport(false);
            setRoomtickets(true);
        }
    }, [link]);

    return (
        <nav className="w-full navbar__footer fixed bottom-0 left-0 z-[9999]">
            <div className="flex justify-center items-center bg-white border-t navbar-style">
                <Link to="/" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            <i className="flex justify-center">
                                <img src={!homeN ? home : home2} alt="home" className="w-8 h-8 sepia-0" />
                            </i>
                        </div>
                        <div
                            className="text-xl lg:text-xl mt-[2px] font-semibold pt-2"
                            style={{ color: `rgb(${!homeN ? '187, 187, 187' : '255, 76, 91'})` }}
                        >
                            Trang Chủ
                        </div>
                    </div>
                </Link>

                <Link to="/order/index" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            <i className="flex justify-center">
                                <img src={!history ? navHistory : navHistoryActive} alt="home" className="w-8 h-8" />
                            </i>
                        </div>
                        <div
                            className="text-xl lg:text-xl mt-[2px] font-semibold pt-2"
                            style={{ color: `rgb(${!history ? '187, 187, 187' : '255, 76, 91'})` }}
                        >
                            Lịch sử
                        </div>
                    </div>
                </Link>

                <Link to="/order/mission" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            <i className="flex justify-center">
                                <img src={!orderN ? mission : mission2} alt="home" className="w-10 h-8" />
                            </i>
                        </div>
                        <div
                            className="text-xl lg:text-xl mt-[2px] font-semibold pt-2"
                            style={{ color: `rgb(${!orderN ? '187, 187, 187' : '255, 76, 91'})` }}
                        >
                            Phòng vé
                        </div>
                    </div>
                </Link>
                {/* <Link to="/roomtickets" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2" data-lc-id="6">
                        <div className="bar-item__icon">
                            <i className="flex justify-center">
                                <img src={!roomtickets ? cophieu : cophieuActive} alt="home" className="w-9 h-9" />
                            </i>
                        </div>
                        <div
                            className="text-xl lg:text-xl mt-[2px] font-semibold pt-2"
                            style={{ color: `rgb(${!roomtickets ? '187, 187, 187' : '255, 76, 91'})` }}
                        >
                            Cổ phiếu
                        </div>
                    </div>
                </Link> */}
                <Link to="/news" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2" data-lc-id="6">
                        <div className="bar-item__icon">
                            <i className="flex justify-center">
                                <img src={!support ? navSupport : navSupportActive} alt="home" className="w-10 h-8" />
                            </i>
                        </div>
                        <div
                            className="text-xl lg:text-xl mt-[2px] font-semibold pt-2"
                            style={{ color: `rgb(${!support ? '187, 187, 187' : '255, 76, 91'})` }}
                        >
                            Tin tức
                        </div>
                    </div>
                </Link>

                <Link to="/my" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            <i className="flex justify-center">
                                <img src={!myN ? my : my2} alt="home" className="w-10 h-8" />
                            </i>
                        </div>
                        <div
                            className="text-xl lg:text-xl mt-[2px] font-semibold pt-2"
                            style={{ color: `rgb(${!myN ? '187, 187, 187' : '255, 76, 91'})` }}
                        >
                            Hồ sơ
                        </div>
                    </div>
                </Link>
            </div>
            <Outlet />
        </nav>
    );
}

export default memo(Navbar);
