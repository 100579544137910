import Header from '../../components/Layout/components/Header';
import styles from './My.module.scss';
import classNames from 'classnames/bind';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import Select from 'react-select';

const axios = require('axios').default;
const domain = process.env.REACT_APP_DOMAIN;

let listBank = [
    { label: 'EXIMBANK', value: 'EXIMBANK' },
    { label: 'MARITIME BANK', value: 'MARITIME BANK' },
    { label: 'AGRIBANK', value: 'AGRIBANK' },
    { label: 'VIETINBANK', value: 'VIETINBANK' },
    { label: 'BAC A BANK', value: 'BAC A BANK' },
    { label: 'BAO VIET BANK', value: 'BAO VIET BANK' },
    { label: 'BIDV BANK', value: 'BIDV BANK' },
    { label: 'GP BANK', value: 'GP BANK' },
    { label: 'HD BANK', value: 'HD BANK' },
    { label: 'HONGLEONG BANK', value: 'HONGLEONG BANK' },
    { label: 'INDOVINA BANK', value: 'INDOVINA BANK' },
    { label: 'KIENLONGBANK', value: 'KIENLONGBANK' },
    { label: 'MBBANK', value: 'MBBANK' },
    { label: 'NAMA BANK', value: 'NAMA BANK' },
    { label: 'NGAN HANG A CHAU', value: 'NGAN HANG A CHAU' },
    { label: 'Ngân hàng TMCP Đông Á', value: 'Ngân hàng TMCP Đông Á' },
    { label: 'Ngân hàng TMCP Việt Á', value: 'Ngân hàng TMCP Việt Á' },
    { label: 'NH LD VIET NGA', value: 'NH LD VIET NGA' },
    { label: 'CIMB', value: 'CIMB' },
    { label: 'NH TMCP QUOC DAN', value: 'NH TMCP QUOC DAN' },
    { label: 'OCEANBANK', value: 'OCEANBANK' },
    { label: 'PGBANK', value: 'PGBANK' },
    { label: 'PHUONGDONG BANK', value: 'PHUONGDONG BANK' },
    { label: 'SACOMBANK', value: 'SACOMBANK' },
    { label: 'SCB', value: 'SCB' },
    { label: 'SEABANK', value: 'SEABANK' },
    { label: 'SHB BANK', value: 'SHB BANK' },
    { label: 'SHINHAN BANK VN', value: 'SHINHAN BANK VN' },
    { label: 'TECHCOMBANK', value: 'TECHCOMBANK' },
    { label: 'TIENPHONG BANK', value: 'TIENPHONG BANK' },
    { label: 'UNITED OVERSEAS BANK', value: 'UNITED OVERSEAS BANK' },
    { label: 'VIB BANK', value: 'VIB BANK' },
    { label: 'VIDPublic Bank', value: 'VIDPublic Bank' },
    { label: 'VIETBANK', value: 'VIETBANK' },
    { label: 'VIETCOMBANK', value: 'VIETCOMBANK' },
    { label: 'VPBANK', value: 'VPBANK' },
    { label: 'WOORI BANK', value: 'WOORI BANK' },
    { label: 'NEWBANK', value: 'NEWBANK' },
];

const cx = classNames.bind(styles);
const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${domain}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
function MyBank({ title }) {
    document.title = title;
    let [nameOnwerBank, setNameOnwerBank] = useState('');
    let [stk, setStk] = useState('');
    let [nameBank, setNameBank] = useState('EXIMBANK');
    let [sdt, setSdt] = useState('');
    let [check, setCheck] = useState('');
    const [soCanCuoc, setSoCanCuoc] = useState('');
    const [ngayCapCanCuoc, setNgayCapCanCuoc] = useState('');
    const [noiCapCanCuoc, setNoiCapCanCuoc] = useState('');

    const handleChangeNameBank = (value) => {
        setNameBank(value.value);
    };

    const handleAddBanking = () => {
        if (!nameOnwerBank || !stk || !nameBank || !sdt || !soCanCuoc || !noiCapCanCuoc || !ngayCapCanCuoc)
            return toast.error('Vui lòng nhập đầy đủ thông tin !', { theme: 'light' });
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${domain}/api/webapi/user/addbanking`,
                { nameuser: nameOnwerBank, stk, nameBank, sdt, soCanCuoc, noiCapCanCuoc, ngayCapCanCuoc, type: 'add' },
                {
                    headers,
                },
            )
            .then(function (response) {
                let data = response.data;
                if (data.data === 1) toast.success('Thêm ngân hàng thành công !', { theme: 'light' });
                if (data.data === 2) toast.success('Sửa ngân hàng thành công !', { theme: 'light' });
                if (data.status) {
                    setTimeout(() => {
                        window.location.href = `/my/settings`;
                    }, 1500);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    useEffect(() => {
        checkToken();
        axios
            .get(`${domain}/api/webapi/user/banking`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                setCheck(data.data);
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    return (
        <div className={cx('edit-password', { '': true })}>
            <Header title="Liên kết ngân hàng" param="/my/settings" />
            <div className="form-edit-password p-[20px] fs-3 mt-[60px]">
                <div className={cx('form-group', { '': true })}>
                    <label className="ml-[6px] font-semibold">Tên chủ thẻ</label>
                    <input
                        value={
                            Array.isArray(check.recharge) && check.recharge.length > 0
                                ? check.recharge[0].name_u_bank
                                : nameOnwerBank
                        }
                        onChange={(e) => setNameOnwerBank(e.target.value)}
                        className="w-[100%]"
                        placeholder="Nhập tên chủ thẻ"
                    />
                </div>
                <div className={cx('form-group', { '': true })}>
                    <label className="ml-[6px] font-semibold">Số tài khoản</label>
                    <input
                        value={
                            Array.isArray(check.recharge) && check.recharge.length > 0
                                ? check.recharge[0].stk_bank
                                : stk
                        }
                        onChange={(e) => setStk(e.target.value)}
                        className="w-[100%]"
                        placeholder="Nhập số tài khoản"
                    />
                </div>

                <div className={cx('form-group', { '': true })}>
                    <label className="ml-[6px] font-semibold">Tên ngân hàng</label>
                    <div className="w-100 py-3">
                        {listBank && <Select options={listBank} onChange={handleChangeNameBank} />}
                    </div>
                </div>

                <div className={cx('form-group', { '': true })}>
                    <label className="ml-[6px] font-semibold">Số căn cước công dân:</label>
                    <input
                        value={
                            Array.isArray(check.recharge) && check.recharge.length > 0
                                ? check.recharge[0].soCanCuoc
                                : soCanCuoc
                        }
                        onChange={(e) => setSoCanCuoc(e.target.value)}
                        className="w-[100%]"
                        placeholder="Nhập số căn cước công dân"
                    />
                </div>
                <div className={cx('form-group', { '': true })}>
                    <label className="ml-[6px] font-semibold">Ngày cấp căn cước: </label>
                    <input
                        value={
                            Array.isArray(check.recharge) && check.recharge.length > 0
                                ? check.recharge[0].ngayCapCanCuoc
                                : ngayCapCanCuoc
                        }
                        onChange={(e) => setNgayCapCanCuoc(e.target.value)}
                        className="w-[100%]"
                        placeholder="Ngày cấp căn cước"
                    />
                </div>
                <div className={cx('form-group', { '': true })}>
                    <label className="ml-[6px] font-semibold">Nơi cấp căn cước:</label>
                    <input
                        value={
                            Array.isArray(check.recharge) && check.recharge.length > 0
                                ? check.recharge[0].noiCapCanCuoc
                                : noiCapCanCuoc
                        }
                        onChange={(e) => setNoiCapCanCuoc(e.target.value)}
                        className="w-[100%]"
                        placeholder="Nơi cấp căn cước"
                    />
                </div>

                <div className={cx('form-group', { '': true })}>
                    <label className="ml-[6px] font-semibold">Số điện thoại</label>
                    <input
                        value={
                            Array.isArray(check.recharge) && check.recharge.length > 0 ? check.recharge[0].phone : sdt
                        }
                        onChange={(e) => setSdt(e.target.value)}
                        className="w-[100%]"
                        placeholder="Nhập số điện thoại"
                    />
                </div>
                {Array.isArray(check.recharge) && check.recharge.length === 0 && (
                    <div onClick={() => handleAddBanking()} className={cx('form-group', { 'text-center': true })}>
                        <div className={cx('btn-submit', { 'text-[#fff]': true })}>Xác nhận</div>
                    </div>
                )}
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default MyBank;
