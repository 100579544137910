import React from 'react';
import imgBCT from '../../assets/images/bocongthuong.png';
import './footer.css';

function Footer() {
    return (
        <div className="copyright-footer mt-3">
            <div className="text-[blue] fs-5 text-center p-2">
                * Công ty sẽ cập nhật và nâng cấp hệ thống định kì vào ngày cuối tháng
            </div>
            <div className="text-[blue] fs-5 text-center p-2">
                CẢM ƠN CÁC BẠN ĐÃ ĐỒNG HÀNH CÙNG TICKETBOX TRONG THỜI GIAN QUA
            </div>
            <div className="ant-image">
                <img src={imgBCT} alt="Bộ Công Thương" width="140px" height="50px" />
            </div>
            <span className="ant-typography fs-3">® Bản quyền thuộc về TICKETBOX VN</span>
        </div>
    );
}

export default Footer;
