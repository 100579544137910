import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '~/components/Layout/components/Navbar';

function Artist() {
    let { state } = useLocation();

    return (
        <div>
            <div className="header_my flex p-4" style={{ borderBottom: '1px dashed gray' }}>
                <div className="fs-1 font-bold pl-2 text-sky-600" style={{ textTransform: 'uppercase' }}>
                    {state?.data.title}
                </div>
            </div>

            <div className="p-8 mr-2">
                <div dangerouslySetInnerHTML={{ __html: state?.data.descArtist }} className="fs-2 pt-8 pb-[80px]"></div>
            </div>

            <Navbar link="/order/mission" />
        </div>
    );
}

export default Artist;
