import React, { useEffect, useState } from 'react';
import tinTucIcon from '~/assets/images/tin_tuc_active.png';
import Navbar from '~/components/Layout/components/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './News.scss';
import { Link } from 'react-router-dom';

const domain = process.env.REACT_APP_DOMAIN;

const axios = require('axios').default;

function News() {
    const [dataNews, setDataNews] = useState();

    useEffect(() => {
        axios
            .get(`${domain}/api/webapi/news`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === true) {
                    setDataNews(data.data);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    return (
        <div>
            <div className="header_my flex p-4" style={{ borderBottom: '1px dashed gray' }}>
                <img src={tinTucIcon} alt="" width={30} />
                <div className="fs-1 font-bold pl-2">Tin tức</div>
            </div>

            <div className="m-4 border rounded-2">
                {dataNews &&
                    dataNews.map((item, i) => {
                        return (
                            <Link to={`/news/${i}`} state={{ data: dataNews[i] }} key={i} className="p-4 flex h-[auto]">
                                <div className="p-1 border">
                                    <img src={item.linkImage} alt="" />
                                </div>
                                <div className="pl-4">
                                    <span className="fs-3 text-sky-600 style-title__content">{item.title}</span>
                                    {/* <span
                                        dangerouslySetInnerHTML={{ __html: item.descNew }}
                                        className="style-text__content fs-4"
                                    ></span> */}
                                    <span className="fs-3 text-black-500">Đọc thêm ...</span>
                                </div>
                            </Link>
                        );
                    })}
            </div>

            <Navbar link="/news" />
        </div>
    );
}

export default News;
