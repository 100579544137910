export const formatMoney = (money = 0) =>
    new Intl.NumberFormat('vi-vn', { currency: 'VND', style: 'currency' }).format(money);

export const formatMoneyUsd = (money = 0) =>
    new Intl.NumberFormat('en-us', { currency: 'USD', style: 'currency' }).format(money);

export const formatCountdownTime = (timeEnd = 0, type = 'confirm') => {
    let now = new Date().getTime();
    let distance;
    if (type === 'frozen') {
        distance = timeEnd;
    } else {
        distance = timeEnd - now;
        distance = distance < 0 ? 0 : distance;
    }

    let dd = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hh = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let mm = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let ss = Math.floor((distance % (1000 * 60)) / 1000);

    dd = dd < 10 ? '0' + dd : dd;
    hh = hh < 10 ? '0' + hh : hh;
    mm = mm < 10 ? '0' + mm : mm;
    ss = ss < 10 ? '0' + ss : ss;

    return `${dd}:${hh}:${mm}:${ss}`;
};

export const formatDate = (date) => {
    date = new Date(date);
    let yy = date.getFullYear();
    let MM = date.getMonth() + 1;
    let dd = date.getDate();
    let hh = date.getHours();
    let mm = date.getMinutes();
    let ss = date.getSeconds();
    MM = MM < 10 ? '0' + MM : MM;
    dd = dd < 10 ? '0' + dd : dd;
    hh = hh < 10 ? '0' + hh : hh;
    mm = mm < 10 ? '0' + mm : mm;
    ss = ss < 10 ? '0' + ss : ss;
    return `${yy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
};
