import ReactLoading from 'react-loading';

const Loading = () => {
    return (
        <div className="fixed top-0 left-0 w-full bg-gray-800/90 h-[100dvh] flex justify-center items-center">
            <ReactLoading type="spin" color="#fff" height="20%" width="20%" />
        </div>
    );
};

export default Loading;
