import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/my.css';
import Navbar from '~/components/Layout/components/Navbar';
import bell from '~/assets/images/bell.png';
import iconRechage from '~/assets/images/icon-recharge2.png';
import iconWithdraw from '~/assets/images/icon-withdraw2.png';
import iconHistory from '~/assets/images/icon-recharge-history2.png';
import levelSilver from '~/assets/images/level_silver.png';
import levelGold from '~/assets/images/level_gold.png';
import levelPalatinum from '~/assets/images/level_platinum.png';
import levelDiamond from '~/assets/images/level_diamond.png';
import verify from '~/assets/images/verify.png';
import hoSoIcon from '~/assets/images/ho_so_red.png';
import thongtincoban from '~/assets/images/icon-profile-info2.png';
import doimatkhau from '~/assets/images/icon-profile-password2.png';
import logout from '~/assets/images/icon-logout.png';
import thongbao from '~/assets/images/icon-message2.png';
import iconEditPWTrans from '~/assets/images/edit_pw.png';
import iconHistoryMoney from '~/assets/images/history_money.png';
import moneyLove from '~/assets/images/money_love.png';

const domain = process.env.REACT_APP_DOMAIN;

const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${domain}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function My() {
    let [user, setUser] = useState([]);
    let [mission, setMission] = useState([]);
    let [iconVip, setIconVip] = useState([]);
    let [userBank, setUserBank] = useState();
    let [moneyEarn, setMoneyEarn] = useState(0);

    useEffect(() => {
        checkToken();
        axios
            .get(`${domain}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    if (data.userBank.name_u_bank) {
                        const arrFullName = data.userBank.name_u_bank.split(' ');
                        if (arrFullName.length > 0) {
                            localStorage.setItem('name', arrFullName[arrFullName.length - 1]);
                        }
                    }

                    setUser(data.data[0]);
                    setMission(data.mission);
                    setIconVip(data.data[0].roses_user);
                    setUserBank(data.userBank);
                    setMoneyEarn(data.data[0].earn_total);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    return (
        <div className="bg-[#ffffff]">
            <div className="min-h-[770px]">
                <div className="header_my flex p-4" style={{ borderBottom: '1px dashed gray' }}>
                    <img src={hoSoIcon} alt="" width={30} />
                    <div className="fs-1 font-bold pl-2">Hồ sơ</div>
                </div>
                <div className="header_home text-white fs-3 font-semibold mt-4">
                    <div className="top_header flex p-4 items-center justify-between">
                        <div className="flex header-user z-[9]">
                            <div className="img-level__user">
                                {(iconVip === 'vip1' || iconVip == 0) && (
                                    <img src={levelSilver} alt="logo-level" width={60} />
                                )}
                                {iconVip === 'vip2' && <img src={levelSilver} alt="logo-level" width={60} />}
                                {iconVip === 'vip3' && <img src={levelSilver} alt="logo-level" width={60} />}
                                {iconVip === 'vip4' && <img src={levelGold} alt="logo-level" width={60} />}
                                {iconVip === 'vip5' && <img src={levelPalatinum} alt="logo-level" width={60} />}
                                {iconVip === 'vip6' && <img src={levelDiamond} alt="logo-level" width={60} />}
                            </div>
                            <div className="info_user px-4">
                                <div className="name_user flex items-center justify-start">
                                    <span>
                                        Xin chào{' '}
                                        <span className="font-bold">
                                            {
                                                <span className="font-bold">
                                                    {localStorage.getItem('name') ? localStorage.getItem('name') : ''}
                                                </span>
                                            }
                                        </span>
                                    </span>
                                    <img src={verify} alt="" width={30} />
                                </div>
                                <div className="point_trust">
                                    <span>
                                        Điểm tín nhiệm: <span className="font-bold text-green-500">{100}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <img src={bell} alt="" width={50} height={50} className="img-bell" />
                    </div>
                    <div className="center_header flex items-center justify-start px-2 py-8">
                        <Link
                            to="/recharge"
                            className="item-header__home pl-8 fs-5 flex-col items-center justify-center"
                        >
                            <img src={iconRechage} alt="" width={40} height={40} className="mb-2" />
                            <span className="text-white">Nạp tiền</span>
                        </Link>
                        <Link
                            to="/withdraw"
                            className="item-header__home pl-8 fs-5 flex-col items-center justify-center"
                        >
                            <img src={iconWithdraw} alt="" width={40} height={40} className="mb-2" />
                            <span className="text-white">Rút tiền</span>
                        </Link>
                        <Link
                            to="/order/index"
                            className="item-header__home pl-8 fs-5 flex-col items-center justify-center"
                        >
                            <img src={iconHistory} alt="" width={40} height={40} className="mb-2" />
                            <span className="text-white">Lịch sử</span>
                        </Link>
                        <Link to="/loves" className="item-header__home pl-8 fs-5 flex-col items-center justify-center">
                            <img src={moneyLove} alt="" width={50} height={50} className="mb-2" />
                            <span className="text-white">Cặp đôi</span>
                        </Link>
                    </div>
                    <div className="bottom_header flex items-center justify-start fs-4">
                        <div className="item-header__home flex-col items-center justify-center">
                            <div>Số dư khả dụng</div>
                            <div className="fs-2 text-yellow-300">{formatMoney(user?.money)} VND</div>
                        </div>
                        <div className="item-header__home flex-col items-center justify-center pl-8">
                            <div>Tổng lợi nhuận</div>
                            <div className="fs-2 text-yellow-300">{formatMoney(user?.earn_total)} VND</div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#fff] mx-auto p-[10px] w-[calc(100%)]">
                    <div className="yt-quick-link-wrap mt-3">
                        <div className="flex-col">
                            <div className="item p-3" style={{ borderBottom: '1px solid #eee' }}>
                                <Link to="/my/user-info" className="w-full py-2 pr-2 flex items-center">
                                    <img src={thongtincoban} alt="Icon Recharge" width={30} />
                                    <div className="title text-[gray] fs-1 font-semibold pl-4">Thông tin cơ bản</div>
                                </Link>
                            </div>
                            <div className="item p-3" style={{ borderBottom: '1px solid #eee' }}>
                                <Link to="/my/edit-password" className="w-full py-2 pr-2 flex items-center">
                                    <img src={doimatkhau} alt="Icon Recharge" width={30} />
                                    <div className="title text-[gray] fs-1 font-semibold pl-4">Đổi mật khẩu</div>
                                </Link>
                            </div>
                            <div className="item p-3" style={{ borderBottom: '1px solid #eee' }}>
                                <Link to="/my/edit-password-transaction" className="w-full py-2 pr-2 flex items-center">
                                    <img src={iconEditPWTrans} alt="Icon Recharge" width={30} />
                                    <div className="title text-[gray] fs-1 font-semibold pl-4">
                                        Đổi mật khẩu rút tiền
                                    </div>
                                </Link>
                            </div>
                            <div className="item p-3" style={{ borderBottom: '1px solid #eee' }}>
                                <Link to="/history-recharge-withdraw" className="w-full py-2 pr-2 flex items-center">
                                    <img src={iconHistoryMoney} alt="Icon Recharge" width={30} />
                                    <div className="title text-[gray] fs-1 font-semibold pl-4">Lịch sử nạp rút</div>
                                </Link>
                            </div>
                            <div className="item p-3" style={{ borderBottom: '1px solid #eee' }}>
                                <a
                                    href="https://direct.lc.chat/15964677/"
                                    target="_blank"
                                    className="w-full py-2 pr-2 flex items-center"
                                >
                                    <img src={thongbao} alt="Icon Recharge" width={30} />
                                    <div className="title text-[gray] fs-1 font-semibold pl-4">Chăm sóc khách hàng</div>
                                </a>
                            </div>
                            <div
                                className="item p-3"
                                style={{ borderBottom: '1px solid #eee' }}
                                onClick={() => {
                                    localStorage.removeItem('auth');
                                    window.location.href = '/account/login';
                                }}
                            >
                                <Link to="/my/user-info" className="w-full py-2 pr-2 flex items-center">
                                    <img src={logout} alt="Icon Recharge" width={30} />
                                    <div className="title text-[red] fs-1 font-semibold pl-4">Đăng xuất</div>
                                </Link>
                            </div>

                            {/* <div className="item">
                                <Link to="/history-recharge-withdraw" className="w-full py-2 pl-2">
                                    <img src={iconRechargeHistory} alt="Icon Recharge History" />
                                    <div className="title">Lịch sử nạp/rút</div>
                                </Link>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="text-[#a5acbc] mt-[10px] text-center fs-3 font-semibold">
                    Phiên bản hiện tại: v1.0.7
                </div>
            </div>

            <Navbar link="/my" />

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default My;
