import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './Login.module.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import gg_fb from '../../assets/images/logo_gg_fbs.png';
import Account from './index';
import Footer from '../../components/Footer/footer';
import logo from '../../assets/images/logo.png';
import '../../style/login.css';
import logoNew from '../../assets/images/logo_new.png';
import iconLogin from '../../assets/images/icon_login.png';
import phone from '../../assets/images/phone.png';
import lock from '../../assets/images/lock.png';
import refIcon from '../../assets/images/account_icon_ref.png';
import backgroundFooter from '../../assets/images/bg-artist.png';

const domain = process.env.REACT_APP_DOMAIN;

const axios = require('axios').default;

const cx = classNames.bind(styles);

function Register(props) {
    const [Lock1, setLock1] = useState(false);
    const [Lock2, setLock2] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const [invite, setInvite] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const path = window.location.pathname;
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        // Cập nhật thời gian mỗi giây
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        // Xóa interval khi component bị unmount
        return () => clearInterval(interval);
    }, []);

    const day = currentTime.getDate().toString().padStart(2, '0');
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0');
    const year = currentTime.getFullYear().toString();
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');

    // Định dạng hiển thị thời gian theo yêu cầu
    const formattedTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    document.title = props.title;

    const checkLock = (data) => {
        if (data === 'password') {
            setLock1(!Lock1);
            return false;
        } else {
            setLock2(!Lock2);
            return false;
        }
    };

    const handleRegister = async (datas) => {
        let { username, password, repassword, invite } = datas;
        let pattern = /^[0-9]*\d$/;
        if (!isChecked)
            return toast.warn('Vui lòng chọn đồng ý với điều khoản dịch vụ của chúng tôi!', { theme: 'light' });
        if (!username || !password || !repassword || !invite)
            return toast.warn('Vui lòng nhập đầy đủ thông tin', { theme: 'light' });
        if (!pattern.test(username)) return toast.warn('Tài khoản không đúng định dạng!', { theme: 'light' });
        if (password !== repassword) return toast.warn('Mật khẩu xác nhận không chính xác!', { theme: 'light' });

        axios
            .post(`${domain}/api/webapi/register`, {
                username: username,
                password: password,
                invite: invite,
            })
            .then(function (response) {
                let status = response.data.status;
                if (status === 'ok') {
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1500);
                    localStorage.setItem('auth', response.data.auth);
                    return toast.success(response.data.message, { theme: 'light' });
                }
                return toast.error(response.data.message, { theme: 'light' });
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    return (
        <div className="yt-main-content">
            <div className="yt-main-content-wrap yt-account-tabs">
                <div className="account-tab-header">
                    <img src={logoNew} alt="" />

                    <div className="count_time">{formattedTime}</div>
                </div>
            </div>

            <div className="fs-1 font-bold p-8">Đăng ký tài khoản</div>

            <div className="input-login">
                <Input
                    onInput={(e) => setUsername(e.target.value)}
                    type="number"
                    placeholder="Số điện thoại"
                    autoComplete="off"
                    maxLength="128"
                    spellCheck="false"
                />
                <img src={phone} alt="" />
            </div>

            <div className="input-login">
                <img src={lock} alt="" />
                <Input
                    onInput={(e) => setPassword(e.target.value)}
                    type={Lock1 ? 'text' : 'password'}
                    placeholder="Mật khẩu"
                    autoComplete="new-password"
                    maxLength="128"
                />
                <FontAwesomeIcon
                    onClick={() => checkLock('password')}
                    // className={cx('icon-lock')}
                    icon={Lock1 ? faLockOpen : faLock}
                    className="svg-pass"
                />
            </div>

            <div className="input-login">
                <img src={lock} alt="" />
                <Input
                    onInput={(e) => setRepassword(e.target.value)}
                    type={Lock2 ? 'text' : 'password'}
                    placeholder="Nhập lại mật khẩu"
                    autoComplete="new-password"
                    maxLength="128"
                />
                <FontAwesomeIcon
                    onClick={() => checkLock('re_password')}
                    // className={cx('icon-lock-open')}
                    icon={Lock2 ? faLockOpen : faLock}
                    className="svg-pass"
                />
            </div>

            <div className="input-login">
                <img src={refIcon} alt="" />
                <Input
                    onInput={(e) => setInvite(e.target.value)}
                    type="tel"
                    placeholder="Nhập mã giới thiệu"
                    autoComplete="new-password"
                    maxLength="128"
                    spellCheck="false"
                />
            </div>

            <label class="container_checked">
                Đồng ý với{' '}
                <span style={{ color: '#0056b3' }} onClick={toggle}>
                    "Điều khoản dịch vụ"
                </span>
                <input type="checkbox" checked={isChecked} onClick={() => setIsChecked(!isChecked)} />
                <span class="checkmark"></span>
            </label>

            <div className="button-login">
                <Button type="button" onClick={() => handleRegister({ username, password, repassword, invite })}>
                    ĐĂNG KÝ
                    <img src={iconLogin} alt="" />
                </Button>
            </div>

            <div className="support-pass">
                <Link to="/account/login" className="fs-3">
                    Đăng nhập tài khoản
                </Link>
            </div>

            <div className="background_footer">
                <img src={backgroundFooter} alt="" />
            </div>

            <div>
                <Modal isOpen={modal} toggle={toggle} {...props} centered>
                    <ModalHeader toggle={toggle}>QUY ĐỊNH CHUNG</ModalHeader>
                    <ModalBody>
                        <span>
                            Người tham gia mở phòng vé phải trong độ tuổi từ 18 đến 60, có tài khoản ngân hàng chính chủ
                            hoặc có thể mượn người thân, có CMT hoặc thẻ căn cước, phải cung cấp số điện thoại chính
                            chủ.
                        </span>
                        <br />
                        <br />
                        <span>
                            Bất kỳ thông tin không chính xác hoặc không rõ ràng nào cũng có thể làm phòng vé của bạn bị
                            đóng băng
                        </span>
                        <br />
                        <br />
                        <span>Vui lòng liên hệ tới sự hỗ trợ của ticketbox VN để được hỗ trợ</span>
                    </ModalBody>
                </Modal>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Register;
