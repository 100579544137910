import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { historyOrder, userInfo } from '../api';
import { checkToken, headers } from './coditional';

const domain = process.env.REACT_APP_DOMAIN;

export const userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: [],
    baseQuery: fetchBaseQuery({ baseUrl: domain }),
    endpoints: (builder) => ({
        getuserInfo: builder.query({
            query: () => ({ url: userInfo, headers: headers() }),
            transformResponse: (res, meta, arg) => {
                checkToken(res);
                return res.data[0];
            },
        }),
        getHistoryTicket: builder.query({
            query: () => ({ url: historyOrder, headers: headers() }),
            transformResponse: (res, meta, arg) => {
                checkToken(res);
                return res.data;
            },
        }),
    }),
});

export const { useGetuserInfoQuery, useGetHistoryTicketQuery } = userApi;
