import Header from '../../components/Layout/components/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import '../../style/history.css';
import imageEmail from '../../assets/images/image-email.png';

const domain = process.env.REACT_APP_DOMAIN;
const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${domain}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function HistoryRechargeWithdraw({ title }) {
    const [activeTab, setActiveTab] = useState('1');
    const [activeTabPane, setActiveTabPane] = useState('1');

    document.title = title;
    let [recharge, setRecharge] = useState([]);
    let [withdraw, setWithdraw] = useState([]);

    useEffect(() => {
        axios
            .get(`${domain}/api/webapi/history-recharge`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setRecharge(data.data.recharge);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${domain}/api/webapi/history-withdraw`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setWithdraw(data.data.withdraw);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    // useEffect(() => {
    //     checkToken();
    //     axios
    //         .get(`${domain}/api/webapi/userInfo`, {
    //             headers: {
    //                 'x-access-token': localStorage.getItem('auth'),
    //                 'Access-Control-Allow-Origin': '*',
    //             },
    //         })
    //         .then(function (response) {
    //             let data = response.data;
    //             if (data.status === 'ok') {
    //                 setUsername(() => data.data[0].username);
    //                 axios
    //                     .get(
    //                         `${domain}/api/webapi/history/${activeTab2}`,
    //                         { params: { username: data.data[0].username } },
    //                         {
    //                             headers: {
    //                                 'x-access-token': localStorage.getItem('auth'),
    //                                 'Access-Control-Allow-Origin': '*',
    //                             },
    //                         },
    //                     )
    //                     .then(function (response) {
    //                         let data = response.data;
    //                         if (data.result.length > 0) {
    //                             setData(() => data.result);
    //                         }
    //                     })
    //                     .catch(function (error) {
    //                         toast.error('Có lỗi xảy ra', { theme: 'light' });
    //                     });
    //             }
    //         })
    //         .catch(function (error) {
    //             toast.error('Có lỗi xảy ra', { theme: 'light' });
    //         });
    // }, []);

    useEffect(() => {
        checkToken();
    }, []);
    if (!Array.isArray(recharge)) return false;

    return (
        <div className="history-order">
            <Header color="rgb(255, 82, 89)" title="Lịch sử nạp rút" param="/my" />
            <div className="history-body">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={activeTab === '1' ? 'active' : ''}
                            onClick={() => {
                                setActiveTabPane('1');
                                setActiveTab('1');
                            }}
                        >
                            Nạp tiền
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === '2' ? 'active' : ''}
                            onClick={() => {
                                setActiveTabPane('2');
                                setActiveTab('2');
                            }}
                        >
                            Rút tiền
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTabPane}>
                    <TabPane tabId="1">
                        <div className="tab-pane">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="col">Thời gian</th>
                                        <th className="col">Biến động</th>
                                        <th className="col">Trạng thái</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recharge &&
                                        recharge.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.create_at}</td>
                                                    <td>
                                                        <span className="badge badge-success">
                                                            {' '}
                                                            + {formatMoney(item.amount)} VND
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {item.status == '2' && (
                                                            <span className="badge badge-fail">Thất bại</span>
                                                        )}
                                                        {item.status == '1' && (
                                                            <span className="badge badge-success">Thành công</span>
                                                        )}
                                                        {item.status == '0' && (
                                                            <span className="badge badge-pending">Chờ xử lý</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>

                        {!activeTab && (
                            <div className="empty">
                                <div className="empty-image">
                                    <img src={imageEmail} alt="Email-Empty" width="120px" height="120px" />
                                </div>
                                <div className="empty-description">Chưa có lịch sử.</div>
                            </div>
                        )}
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="tab-pane">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="col">Thời gian</th>
                                        <th className="col">Biến động</th>
                                        <th className="col">Trạng thái</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {withdraw &&
                                        withdraw.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.create_at}</td>
                                                    <td>
                                                        <span className="badge withdraw-success">
                                                            {' '}
                                                            - {formatMoney(item.amount)} VND
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {item.status == '2' && (
                                                            <span className="badge badge-fail">Thất bại</span>
                                                        )}
                                                        {item.status == '1' && (
                                                            <span className="badge badge-success">Thành công</span>
                                                        )}
                                                        {item.status == '0' && (
                                                            <span className="badge badge-pending">Chờ xử lý</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default HistoryRechargeWithdraw;
