import classNames from 'classnames/bind';
import { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Mission.module.scss';
import './mission.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import lockIcon from '~/assets/images/lock_icon.png';

const domain = process.env.REACT_APP_DOMAIN;
const axios = require('axios').default;

const cx = classNames.bind(styles);

let pattern = /[0-9]/g;

const formatMoney = (money) => Intl.NumberFormat().format(money);

function Mission(prop) {
    let mission = prop.mission || [];
    return (
        <>
            {Array.isArray(mission.missions) &&
                mission.missions.map((data, index) => {
                    if (index === 0 || index === 1) return;

                    return (
                        <Link to={'/order/mission/' + data.id_level} key={index} className="w-[50%]">
                            <div
                                className={`item-mission ${
                                    Number(mission.roses_user.match(pattern)[0]) ==
                                    Number(data.id_level.match(pattern)[0])
                                        ? 'active'
                                        : ''
                                }`}
                                style={{ position: 'relative', display: 'inline-block' }}
                            >
                                <div className="image-mission">
                                    <img
                                        src={domain + '/static/media/' + data.id_level + '.png'}
                                        draggable="true"
                                        width="100%"
                                        alt="mission"
                                    />
                                </div>
                                <div className="detail">
                                    {index === 2 && (
                                        <div
                                            className="fs-2 font-semibold bottom_header text-center"
                                            style={{ color: 'yellow' }}
                                        >
                                            Phòng vé Silver
                                        </div>
                                    )}
                                    {index === 3 && (
                                        <div
                                            className="fs-2 font-semibold bottom_header text-center"
                                            style={{ color: 'yellow' }}
                                        >
                                            Phòng vé Gold
                                        </div>
                                    )}
                                    {index === 4 && (
                                        <div
                                            className="fs-2 font-semibold bottom_header text-center"
                                            style={{ color: 'yellow' }}
                                        >
                                            Phòng vé Platinum
                                        </div>
                                    )}
                                    {index === 5 && (
                                        <div
                                            className="fs-2 font-semibold bottom_header text-center"
                                            style={{ color: 'yellow' }}
                                        >
                                            Phòng vé Diamond
                                        </div>
                                    )}

                                    <div className="commission css-font-mission text-center fs-4 font-semibold">
                                        {data.roses}% | {data.amount} vé
                                    </div>
                                    <div className="value css-font-mission text-center fs-4 font-semibold">
                                        {formatMoney(data.price)}
                                    </div>
                                </div>
                                <div className="icon-wrap">
                                    <div
                                        className={`icon ${
                                            Number(mission.roses_user.match(pattern)[0]) ==
                                            Number(data.id_level.match(pattern)[0])
                                                ? 'icon-vip'
                                                : ''
                                        }`}
                                    >
                                        <img
                                            src={domain + '/static/media/' + data.id_level + '_icon.png'}
                                            draggable="true"
                                            width="100%;"
                                            alt="mission"
                                        />

                                        {Number(mission.roses_user.match(pattern)[0]) <
                                            Number(data.id_level.match(pattern)[0]) && (
                                            <div className={cx('lock')}>
                                                <img src={lockIcon} draggable="true" alt="img" width="30px" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    );
                })}
        </>
    );
}

export default memo(Mission);
