import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import bgImg from '~/assets/images/bg-image-1.png';
import { formatCountdownTime, formatMoney, formatMoneyUsd } from '~/utils/helper';

const CardItem = ({ data }) => {
    const timeOut = useRef();
    const [countdownTime, setCountdownTime] = useState('00:00:00:00');

    useEffect(() => {
        if (data?.status === 'confirm') {
            timeOut.current = setInterval(() => {
                setCountdownTime(formatCountdownTime(data?.time_end, 'confirm'));
            }, 1000);
        }
        if (data?.status === 'frozen') {
            setCountdownTime(formatCountdownTime(data?.frozen_time, 'frozen'));
            toast.warn('Gói cổ phiếu của bạn đang bị đóng băng! Vui lòng liên hệ chăm sóc khách hàng', {
                autoClose: false,
            });
        }
        // let time_end = data?.frozen_time || data?.time_end;
        // let type = data?.frozen_time !== 0;
        // timeOut.current = setInterval(() => {
        //     setCountdownTime(formatCountdownTime(time_end, type));
        // }, 1000);
        return () => {
            clearInterval(timeOut.current);
        };
    }, [data]);

    return (
        <div style={{ background: `url(${bgImg})` }} className="text-white m-4 rounded-lg p-4 text-2xl">
            <span className="font-bold">Gói đầu tư ({data?.name || 0})</span>
            <div className="grid grid-cols-2 mt-4">
                <div className="flex flex-col">
                    <span className="">Lợi nhuận</span>
                    <span className="font-bold text-3xl my-2">{formatMoney(data?.profit || 0)}</span>
                    <span className="font-bold text-3xl">{formatMoneyUsd(data?.profit / 24000 || 0)}</span>
                </div>
                <div className="flex flex-col">
                    <span>Thời gian kết thúc</span>
                    <span className="font-bold text-3xl my-2">{countdownTime}</span>
                </div>
            </div>
        </div>
    );
};

export default CardItem;
