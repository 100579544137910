import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getMovieMain, getListArtist } from '../api';
import { checkToken, headers } from './coditional';

const domain = process.env.REACT_APP_DOMAIN;

export const pageApi = createApi({
    reducerPath: 'pageApi',
    tagTypes: [],
    baseQuery: fetchBaseQuery({ baseUrl: domain }),
    endpoints: (builder) => ({
        getMovieMain: builder.query({
            query: () => ({ url: getMovieMain, headers: headers() }),
            transformResponse: (res, meta, arg) => {
                checkToken(res);
                return res.data;
            },
        }),

        getListArtist: builder.query({
            query: () => ({ url: getListArtist, headers: headers() }),
            transformResponse: (res, meta, arg) => {
                checkToken(res);
                return res.result;
            },
        }),
    }),
});

export const { useGetMovieMainQuery, useGetListArtistQuery } = pageApi;
