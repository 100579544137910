import React, { Fragment } from 'react';
import { formatMoney } from '~/utils/helper';

const HistoryOrder = ({ activeTab2, activeTab, data, isLoading }) => {
    if (isLoading) return;

    data = data[activeTab];
    data = data.filter((item) => item.status === activeTab2);

    return (
        <Fragment>
            {data.map((item) => {
                return (
                    <div key={item.id} className="text-2xl border rounded-xl mt-4 px-4">
                        <div className="flex justify-between py-3 border-b">
                            <span className="font-bold">UNI-TICKET-{item.id}</span>
                            <span
                                style={{
                                    color:
                                        activeTab2 === 'pending'
                                            ? '#0056b3'
                                            : activeTab2 === 'success'
                                            ? '#01a601'
                                            : 'red',
                                }}
                            >
                                {activeTab2 === 'pending'
                                    ? 'Chờ xử lý'
                                    : activeTab2 === 'success'
                                    ? 'Thành công'
                                    : 'Thất bại'}
                            </span>
                        </div>
                        <div className="grid grid-cols-2 py-4 border-b">
                            <span className="">Số tiền đầu tư:</span>
                            <span className="pt-2 font-bold">{formatMoney(item.price)}</span>
                            <span className="pt-2 ">Cấp:</span>
                            <span className="pt-2 font-bold">{item.id_level}</span>
                            <span className="pt-2 ">Lợi nhuận:</span>
                            <span className="pt-2 font-bold">{item.roses}%</span>
                            <span className="pt-2 ">Số tiền nhận lại:</span>
                            <span className="pt-2 font-bold">{formatMoney(item.receive)}</span>
                        </div>
                        <div className="py-4">
                            Thời gian đặt hàng: <span className="font-bold">{item.create_at}</span>
                        </div>
                    </div>
                );
            })}
        </Fragment>
    );
};

export default HistoryOrder;
