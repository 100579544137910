import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '~/components/Layout/components/Navbar';

function ContentNew() {
    let { state } = useLocation();

    return (
        <div>
            <div className="header_my flex p-4" style={{ borderBottom: '1px dashed gray' }}>
                <div className="fs-2 font-bold pl-2 text-sky-600">{state?.data.title}</div>
            </div>

            <div className="p-8 mr-2">
                <iframe
                    width="100%"
                    height="250"
                    src={state?.data.linkYoutube}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                ></iframe>

                <div dangerouslySetInnerHTML={{ __html: state?.data.descNew }} className="fs-2 pt-8"></div>
            </div>

            <Navbar link="/news" />
        </div>
    );
}

export default ContentNew;
