import Header from '../../components/Layout/components/Header';
import styles from './My.module.scss';
import classNames from 'classnames/bind';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const domain = process.env.REACT_APP_DOMAIN;
const axios = require('axios').default;

const cx = classNames.bind(styles);
const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${domain}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function MoneyHeart({ title }) {
    let [phoneReceive, setPhoneReceive] = useState();
    let [money, setMoney] = useState();
    let [reason, setReason] = useState();
    let [user, setUser] = useState();
    let [modal, setModal] = useState(false);
    let [isButton, setIsButton] = useState(true);

    const toggle = () => {
        if (!phoneReceive || !money || !reason)
            return toast.warning('Vui lòng nhập đầy đủ thông tin!', { theme: 'light' });

        if (Number(money) > Number(user?.money) || Number(money <= 0))
            return toast.error('Giá trị chuyển khoản không hợp lệ!', { theme: 'light' });

        setModal(!modal);
    };

    useEffect(() => {
        checkToken();
        axios
            .get(`${domain}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data.data[0]);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    useEffect(() => {
        checkToken();
    }, []);

    function handleMoneyHeart() {
        setIsButton(false);
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };

        if (!phoneReceive || !money || !reason)
            return toast.warning('Vui lòng nhập đầy đủ thông tin!', { theme: 'light' });

        if (Number(money) > Number(user?.money) || Number(money <= 0))
            return toast.error('Giá trị chuyển khoản không hợp lệ!', { theme: 'light' });

        axios
            .post(
                `${domain}/api/webapi/money-heart`,
                { phoneReceive, money, reason },
                {
                    headers,
                },
            )
            .then(function (response) {
                setIsButton(true);
                let data = response.data;
                setModal(false);

                if (data.status === false) {
                    return toast.error(data.msg, { theme: 'light' });
                } else {
                    toast.success(data.msg, { theme: 'light' });
                    setTimeout(() => {
                        window.location.href = '/loves';
                    }, 2000);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }

    document.title = title;

    return (
        <div className={cx('edit-password', { '': true })}>
            <Header title="Tài chính cặp đôi" color="rgb(255, 82, 89)" param="/my/settings" />
            <div className="form-edit-password px-[20px] pt-[60px]">
                <div className={cx('form-group', { '': true })}>
                    <input
                        type="tel"
                        onChange={(e) => setPhoneReceive(e.target.value)}
                        className="w-[100%]  fs-5"
                        placeholder="Số điện thoại người nhận"
                    />
                </div>
                <div className={cx('form-group', { '': true })}>
                    <input
                        type="number"
                        onChange={(e) => setMoney(e.target.value)}
                        className="w-[100%]  fs-5"
                        placeholder="Giá trị chuyển khoản"
                    />
                </div>
                <div className={cx('form-group', { '': true })}>
                    <input
                        type="text"
                        onChange={(e) => setReason(e.target.value)}
                        className="w-[100%]  fs-5"
                        placeholder="Lý do chuyển khoản"
                    />
                </div>
                <div className=" fs-4 pb-4">
                    Số dư hiện tại:{' '}
                    <span className="font-semibold" style={{ color: '#007a00' }}>
                        {formatMoney(user?.money)} VNĐ
                    </span>
                </div>
                <div className=" fs-4 pb-4">
                    Giá trị chuyển khoản:{' '}
                    <span className="font-semibold" style={{ color: '#007a00' }}>
                        {formatMoney(money || 0)} VNĐ
                    </span>
                </div>
                <div className=" fs-4 pb-4">
                    Số dư sau chuyển khoản:{' '}
                    <span className="font-semibold" style={{ color: '#a20000' }}>
                        {formatMoney(money ? user?.money - money : user?.money)} VNĐ
                    </span>
                </div>
                <div
                    // onClick={() => toggle()}
                    onClick={() => handleMoneyHeart()}
                    className={cx('form-group', { 'text-center': true })}
                >
                    <div className={cx('btn-submit', { 'text-[#fff] fs-4 font-bold': true })}>XÁC NHẬN</div>
                </div>
                <div className="text-center fs-4 text-[#ff2ad1] font-bold">
                    Lưu ý: Chỉ có tài khoản đăng ký cặp đôi mới có thể chuyển khoản, chi tiết liên hệ CSKH
                </div>
            </div>

            {/* <Modal isOpen={modal} toggle={toggle} {...title} centered>
                <ModalHeader toggle={toggle}>XÁC NHẬN CHUYỂN TIỀN</ModalHeader>
                <ModalBody>
                    <div>Xác nhận chuyển tiền tới số điện thoại: {phoneReceive}</div>
                    <div>Giá trị chuyển khoản : {formatMoney(money || 0)} VNĐ</div>
                    <div>Lý do: {reason}</div>
                </ModalBody>
                <ModalFooter>
                    {isButton && (
                        <Button color="primary" onClick={() => handleMoneyHeart()} size="lg">
                            ĐỒNG Ý
                        </Button>
                    )}

                    <Button color="secondary" onClick={toggle} size="lg">
                        HỦY
                    </Button>
                </ModalFooter>
            </Modal> */}

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default MoneyHeart;
