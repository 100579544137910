export const register = '/api/webapi/register';
export const login = '/api/webapi/login';
export const userInfo = '/api/webapi/userInfo';
export const me = '/api/webapi/me';
export const listBanner = '/api/webapi/list/banners';
export const listMovies = '/api/webapi/list/movies';
export const getMovieMain = '/api/webapi/movie-main';
export const getListArtist = '/api/webapi/artist';
export const addRecharge = '/api/webapi/recharge/add';
export const getRecharge = '/api/webapi/recharge/:id';
export const changePassword = '/api/webapi/change_password';
export const changePasswordTransaction = '/api/webapi/edit-password-transaction';
export const addBanking = '/api/webapi/user/addbanking';
export const checkBanking = '/api/webapi/user/banking';
export const withdraw = '/api/webapi/user/withdraw';
export const financial = '/api/webapi/user/financial-details';
export const upgrade = '/api/webapi/user/upgrade';
export const listSupport = '/api/webapi/support';
export const historyOrder = '/api/webapi/history/order';
export const reduceTickets = '/api/webapi/reduce-tickets';
export const moneyEarn = '/api/webapi/money-earn';
export const getHistoryRecharge = '/api/webapi/history-recharge';
export const getHistoryWithdraw = '/api/webapi/history-withdraw';
export const roomTicket = '/api/webapi/roomticket';
export const roomTicketInfo = '/api/webapi/roomticket/info';
export const roomTicketRegister = '/api/webapi/roomticket/register';
export const savingsMoneyHistory = '/api/webapi/user/savingsmoney/history';
export const savingsMoney = '/api/webapi/user/savingsmoney';
