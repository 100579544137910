import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/logo.png';
import './header.css';
import backIcon from '~/assets/images/back.png';

function Header(props) {
    return (
        <header className="yt-sticky-element" id="yt-header">
            <Link className="navbar-brand" to="/">
                <img className="rounded-circle" src={backIcon} alt="Logo" />
            </Link>

            <span className="fs-1 font-bold">{props.title}</span>

            <span style={{ color: 'transparent' }}>1111111</span>
        </header>
    );
}

export default Header;
