import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import Mission from '../../components/Mission';
import './Home.css';
import { Link } from 'react-router-dom';
import ListHome from './ListHome';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Navbar from '~/components/Layout/components/Navbar';
import bell from '~/assets/images/bell.png';
import iconRechage from '~/assets/images/icon-recharge2.png';
import iconWithdraw from '~/assets/images/icon-withdraw2.png';
import iconHistory from '~/assets/images/icon-recharge-history2.png';
import levelSilver from '~/assets/images/level_silver.png';
import levelGold from '~/assets/images/level_gold.png';
import levelPalatinum from '~/assets/images/level_platinum.png';
import levelDiamond from '~/assets/images/level_diamond.png';
import verify from '~/assets/images/verify.png';
import NewRun from '~/components/NewRun/NewRun';

const domain = process.env.REACT_APP_DOMAIN;
const axios = require('axios').default;

const cx = classNames.bind(styles);

function formatMoney(money = 0) {
    return new Intl.NumberFormat().format(money);
}
const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${domain}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
function Home(args) {
    let [user, setUser] = useState([]);
    let [iconVip, setIconVip] = useState([]);
    const [modalOne, setModalOne] = useState(false);
    const [modalTwo, setModalTwo] = useState(false);
    const [modalThree, setModalThree] = useState(false);
    let [mission, setMission] = useState({ missions: [] });

    const toggleOne = () => setModalOne(!modalOne);
    const toggleTwo = () => setModalTwo(!modalTwo);
    const toggleThree = () => setModalThree(!modalThree);

    useEffect(() => {
        checkToken();
        axios
            .get(`${domain}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    if (data.userBank.name_u_bank) {
                        const arrFullName = data.userBank.name_u_bank.split(' ');
                        if (arrFullName.length > 0) {
                            localStorage.setItem('name', arrFullName[arrFullName.length - 1]);
                        }
                    }
                    setUser(data.data[0]);
                    setIconVip(data.data[0].roses_user);
                    // console.log(data.data[0]);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
        axios
            .get(`${domain}/api/webapi/mission/list`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                setMission(data.data);
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);
    return (
        <div id={cx('Home')}>
            <div className="header_home text-white fs-3 font-semibold">
                <div className="top_header flex p-4 items-center justify-between">
                    <div className="flex header-user z-[9]">
                        <div className="img-level__user">
                            {(iconVip === 'vip1' || iconVip == 0) && (
                                <img src={levelSilver} alt="logo-level" width={60} />
                            )}
                            {iconVip === 'vip2' && <img src={levelSilver} alt="logo-level" width={60} />}
                            {iconVip === 'vip3' && <img src={levelSilver} alt="logo-level" width={60} />}
                            {iconVip === 'vip4' && <img src={levelGold} alt="logo-level" width={60} />}
                            {iconVip === 'vip5' && <img src={levelPalatinum} alt="logo-level" width={60} />}
                            {iconVip === 'vip6' && <img src={levelDiamond} alt="logo-level" width={60} />}
                        </div>
                        <div className="info_user px-4">
                            <div className="name_user flex items-center justify-start">
                                <span>
                                    Xin chào{' '}
                                    <span className="font-bold">
                                        {localStorage.getItem('name') ? localStorage.getItem('name') : ''}
                                    </span>
                                </span>
                                <img src={verify} alt="" width={30} />
                            </div>
                            <div className="point_trust">
                                <span>
                                    Điểm tín nhiệm: <span className="font-bold text-green-500">{100}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <img src={bell} alt="" width={50} height={50} className="img-bell" />
                </div>
                <div className="center_header flex items-center justify-start px-2 py-8">
                    <Link to="/recharge" className="item-header__home pl-8 fs-5 flex-col items-center justify-center">
                        <img src={iconRechage} alt="" width={40} height={40} className="mb-2" />
                        <span className="text-white">Nạp tiền</span>
                    </Link>
                    <Link to="/withdraw" className="item-header__home pl-8 fs-5 flex-col items-center justify-center">
                        <img src={iconWithdraw} alt="" width={40} height={40} className="mb-2" />
                        <span className="text-white">Rút tiền</span>
                    </Link>
                    <Link
                        to="/order/index"
                        className="item-header__home pl-8 fs-5 flex-col items-center justify-center"
                    >
                        <img src={iconHistory} alt="" width={40} height={40} className="mb-2" />
                        <span className="text-white">Lịch sử</span>
                    </Link>
                </div>
                <div className="bottom_header flex items-center justify-start fs-4">
                    <div className="item-header__home flex-col items-center justify-center">
                        <div>Số dư khả dụng</div>
                        <div className="fs-2 text-yellow-300">{formatMoney(user?.money)} VND</div>
                    </div>
                    <div className="item-header__home flex-col items-center justify-center pl-8">
                        <div>Tổng lợi nhuận</div>
                        <div className="fs-2 text-yellow-300">{formatMoney(user?.earn_total)} VND</div>
                    </div>
                </div>
            </div>

            <main className="px-4 pt-4 lg:px-10 lg:py-6 pb-[40px]">
                <Modal isOpen={modalOne} toggle={toggleOne} {...args}>
                    <div className="modal-home-style">
                        <ModalHeader toggle={toggleOne}>THÔNG TIN HỆ THỐNG</ModalHeader>
                        <ModalBody>
                            <div className="leading-10 fs-3">
                                <div className="fs-1 font-semibold">
                                    Nền tảng phân phối vé sự kiện trực tuyến đầu tiên tại Việt Nam
                                </div>
                                <div className="mt-4">
                                    Với những người từng đi xem các sự kiện âm nhạc nổi tiếng như concert thường niên
                                    của các ca sĩ,nghệ sĩ,danh hài hay chuỗi liveshow,Ticketbox không còn là cái tên xa
                                    lạ.
                                </div>
                                <div className="mt-4">
                                    Tiền thân của Ticketbox là Keewi – một startup được thành lập từ năm 2011, đã nhiều
                                    lần thay đổi định hướng nhưng luôn tập trung vào các sự kiện. Tháng 10/2013, Keewi
                                    chính thức đổi tên thành Ticketbox, trở thành nền tảng phân phối vé sự kiện trực
                                    tuyến đầu tiên tại Việt Nam.
                                </div>
                                <div className="mt-4">
                                    Nhà sáng lập Trần Tuấn Anh cho biết việc đổi tên Keewi thành Ticketbox là một phần
                                    của cuộc cải tổ thương hiệu, phục vụ chiến lược tiếp cận những thị trường lớn hơn.
                                    Ngoài ra, địa chỉ trang web ticketboxvn.com cũng dễ nhớ hơn keewi.me rất nhiều.
                                </div>
                                <div className="mt-4">
                                    Theo ông Trần Tuấn Anh, Ticketbox ra đời từ ý tưởng rất đơn giản là giúp mọi người
                                    mua vé các sự kiện một cách dễ dàng hơn, không phải chạy đến phòng vé xếp hàng chờ
                                    đợi, đôi khi lại phải mua với giá rất cao.
                                </div>
                                <div className="mt-4">
                                    Tính đến năm 2016, Ticketbox đã phục vụ cho hơn 6.000 sự kiện lớn nhỏ, là đối tác
                                    của khoảng 700-800 công ty tổ chức sự kiện tại Việt Nam, thậm chí có tham vọng mở
                                    rộng phạm vi hoạt động ra một số quốc gia Đông Nam Á như Singapore, Thái Lan,
                                    Malaysia…
                                </div>
                                <div className="mt-4">
                                    Ngoài các giải pháp thanh toán và phân phối nhanh thông qua nền tảng trực tuyến,
                                    Ticketbox còn cung cấp cho các đối tác những phân tích về hành vi khách hàng thông
                                    qua dữ liệu lớn, giúp họ hướng đến đúng đối tượng mục tiêu.
                                </div>
                                <div className="mt-4">
                                    Mọi thắc mắc và góp ý bạn có thể phản hồi về hotmail chính thức của hệ thống Công ty
                                    chúng tôi, xin cảm ơn!{' '}
                                </div>
                            </div>
                        </ModalBody>
                    </div>
                </Modal>

                <Modal isOpen={modalTwo} toggle={toggleTwo} {...args}>
                    <div className="modal-home-style">
                        <ModalHeader toggle={toggleTwo}>CHẾ ĐỘ ĐẠI LÝ</ModalHeader>
                        <ModalBody>
                            Công Ty TNHH Ticketbox chúng tôi chịu trách nhiệm cung cấp và phân phối vé các show ca nhạc,
                            live concert thông qua hệ thống đại lý thay vì phân phối trực tiếp. Chúng tôi đã có sẵn một
                            hệ thống bán vé ổn định với nguồn khách hàng, người mua vé dồi dào. Chúng tôi cần sự hợp tác
                            để đẩy mạnh việc đưa số lượng vé xuống cho các đại lý. Để phục vụ các đại lý tốt hơn, công
                            ty chúng tôi đã chuyển hoạt động kinh doanh từ cửa hàng offline sang trực tuyến, giúp đại lý
                            có thể mua vé xem sự kiện trên điện thoại, máy tính,... bán hàng và mua vé sự kiện. Các đại
                            lý sẽ nhận bán vé và lấy phần trăm hoa hồng, sau đó phân phối lại cho các kênh mà họ đã có
                            sẵn. Việc bán vé qua đại lý là một việc làm văn minh, thay vì để nhà sản xuất đứng ra ôm đồm
                            như trước đây. Bởi số tiền thu được (tùy theo sự thương lượng với đại lý thường rơi vào
                            khoảng 20% tiền hoa hồng trên một đầu vé) sẽ là một khoản đảm bảo cho nhà sản xuất trong
                            việc thu hồi lại vốn đầu tư. Rất hân hạnh được hợp tác và đồng hành cùng bạn - đối tác của
                            chúng tôi!
                        </ModalBody>
                    </div>
                </Modal>

                <Modal isOpen={modalThree} toggle={toggleThree} {...args}>
                    <div className="modal-home-style">
                        <ModalHeader toggle={toggleThree}>QUY TẮC</ModalHeader>
                        <ModalBody>
                            <div>
                                <div>
                                    <div className="flex items-center justify-center">
                                        <img width={50} src={levelSilver} alt="" />
                                        <div className="fs-2 font-bold pl-2">
                                            Phòng vé Silver: {formatMoney(mission?.missions[2]?.price)} VND
                                        </div>
                                    </div>
                                    <div className="pl-8 py-4">
                                        <ul style={{ listStyleType: 'disc' }}>
                                            <li>
                                                Khán đài A: {formatMoney(mission?.missions[0]?.price)} VND -{' '}
                                                {mission?.missions[0]?.amount} vé, mức lương thu nhập{' '}
                                                {mission?.missions[0]?.roses}%
                                            </li>
                                            <li>
                                                Khán đài B: {formatMoney(mission?.missions[1]?.price)} VND -{' '}
                                                {mission?.missions[1]?.amount} vé, mức lương thu nhập{' '}
                                                {mission?.missions[1]?.roses}%
                                            </li>
                                            <li>
                                                Khán đài C: {formatMoney(mission?.missions[2]?.price)} VND -{' '}
                                                {mission?.missions[2]?.amount} vé, mức lương thu nhập{' '}
                                                {mission?.missions[2]?.roses}%
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center py-4">
                                    <img width={50} src={levelGold} alt="" />
                                    <div className="fs-2 font-bold pl-2">
                                        Phòng vé Gold: {formatMoney(mission?.missions[3]?.price)} VND -{' '}
                                        {mission?.missions[3]?.amount} vé, mức lương thu nhập{' '}
                                        {mission?.missions[3]?.roses}%
                                    </div>
                                </div>
                                <div className="flex items-center justify-center py-4">
                                    <img width={50} src={levelPalatinum} alt="" />
                                    <div className="fs-2 font-bold pl-2">
                                        Phòng vé Platinum: {formatMoney(mission?.missions[4]?.price)} VND -{' '}
                                        {mission?.missions[4]?.amount} vé, mức lương thu nhập{' '}
                                        {mission?.missions[4]?.roses}%
                                    </div>
                                </div>
                                <div className="flex items-center justify-center py-4">
                                    <img width={50} src={levelDiamond} alt="" />
                                    <div className="fs-2 font-bold pl-2">
                                        Phòng vé Diamond: {formatMoney(mission?.missions[5]?.price)} VND -{' '}
                                        {mission?.missions[5]?.amount} vé, mức lương thu nhập{' '}
                                        {mission?.missions[5]?.roses}%
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </div>
                </Modal>

                {/* End Modal */}

                <div className="flex items-center justify-around mt-3">
                    <div className="img-app" id="modalBtn1" onClick={toggleOne}>
                        <img src={domain + '/static/media/icon-app-info.png'} draggable="true" width="100%;" />
                        Thông tin hệ thống
                    </div>
                    <div className="img-app" id="modalBtn2" onClick={toggleTwo}>
                        <img src={domain + '/static/media/icon-agency.png'} draggable="true" width="100%;" />
                        Chế độ Đại lý
                    </div>
                    <div className="img-app" id="modalBtn3" onClick={toggleThree}>
                        <img src={domain + '/static/media/icon-rule.png'} draggable="true" width="100%;" />
                        Quy tắc
                    </div>
                </div>

                <NewRun />

                <hr className="mt-8" />

                <div className="phong-ve__home">
                    <div className="grid grid-cols-2 gap-x-0 gap-y-1 xl:gap-1 pt-[38px] pl-3 style-mission__home">
                        <Mission mission={mission} />
                    </div>
                </div>

                <hr className="mt-8" />

                <div className="hoi-vien__home mt-8">
                    <div className="h-[360px] pt-[12px] style-list__home">
                        <ListHome amount={4} />
                    </div>
                </div>
            </main>

            <div className="text-[#a5acbc] mb-[90px] text-center fs-3 font-semibold">Phiên bản hiện tại: v1.0.7</div>

            <Navbar link="/" />

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Home;
