import { faAnglesRight, faWallet, faCheck, faL } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Header from '../../components/Layout/components/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/recharge.css';
import imageCSKH from '../../assets/images/cskh-avatar.png';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import silverIcon from '~/assets/images/level_silver.png';
import goldIcon from '~/assets/images/level_gold.png';
import platinumIcon from '~/assets/images/level_platinum.png';
import diamoundIcon from '~/assets/images/level_diamond.png';
import { Link } from 'react-router-dom';

const domain = process.env.REACT_APP_DOMAIN;
const axios = require('axios').default;

function formatMoney(money = 0) {
    return new Intl.NumberFormat('vi-vn', { style: 'currency', currency: 'VND' }).format(money);
}
const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${domain}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

let pattern = /[0-9]/g;

function Recharge({ title }, args) {
    document.title = title;
    let [select, setSelect] = useState('bank');
    let [money, setMoney] = useState(0);
    let [moneyS, setMoneyS] = useState(() => formatMoney());
    let [user, setUser] = useState([]);
    let [support, setSupport] = useState();
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [hideButton, setHideButton] = useState(true);
    const [numberVip, setNumberVip] = useState(true);

    const toggle = () => setModal(!modal);
    const toggle2 = () => setModal2(!modal2);
    const toggleHideButton = (prev) => setHideButton(prev);

    useEffect(() => {
        axios
            .get(`${domain}/api/webapi/support`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                setSupport(data.data);
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    useEffect(() => {
        checkToken();
        axios
            .get(`${domain}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data.data[0]);
                    setNumberVip(Number(data.data[0].roses_user.match(pattern)[0]));
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    function checkMoney() {
        if (!money || money < 2000000) {
            return toast.warn('Đơn nạp tối thiểu là 2.000.000 VNĐ', { theme: 'light' });
        } else return true;
    }

    const handleChangeMoney = (value) => {
        if (numberVip > 1 && value < 5000000) return;
        if (numberVip > 2 && value < 10000000) return;
        if (numberVip > 3 && value < 20000000) return;
        if (numberVip > 4 && value < 50000000) return;
        if (numberVip > 5 && value < 100000000) return;

        setMoney(value);
        money = formatMoney(value);
        setMoneyS(money);
    };

    function RechargeHandler() {
        toggleHideButton(false);

        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${domain}/api/webapi/recharge/add`,
                { money, select },
                {
                    headers,
                },
            )
            .then(function (response) {
                let { status, msg } = response.data;
                if (status) {
                    toast.success(msg, { theme: 'light' });
                    setTimeout(() => {
                        toggle2();
                        toggle();
                    }, 5000);
                } else {
                    toast.warn(msg, { theme: 'light' });
                    toggle();
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }

    return (
        <div>
            <Header title="Quản lý nạp tiền" param="/my" />

            <div className="flex flex-col mt-24 fs-1 font-semibold text-black p-4">
                <div className={`flex items-center justify-center border rounded-3 p-3`}>
                    <img src={silverIcon} alt="" width={50} className="pr-2" />
                    <span>Phòng vé Silver</span>
                </div>

                <div className="flex items-center justify-between mt-4">
                    <div
                        className="flex items-center justify-center border rounded-3 py-4 px-4 text-center"
                        onClick={() => handleChangeMoney(3000000)}
                        style={money === 3000000 ? { backgroundColor: '#C23333', color: 'white' } : {}}
                    >
                        Khán đài A
                    </div>
                    <div
                        className="flex items-center justify-center border rounded-3 py-4 px-4 text-center ml-4"
                        onClick={() => handleChangeMoney(5000000)}
                        style={money === 5000000 ? { backgroundColor: '#C23333', color: 'white' } : {}}
                    >
                        Khán đài B
                    </div>
                    <div
                        className="flex items-center justify-center border rounded-3 py-4 px-4 text-center ml-4"
                        onClick={() => handleChangeMoney(10000000)}
                        style={money === 10000000 ? { backgroundColor: '#C23333', color: 'white' } : {}}
                    >
                        Khán đài C
                    </div>
                </div>

                <div
                    className="flex items-center justify-center border rounded-3 p-3 mt-4"
                    onClick={() => handleChangeMoney(20000000)}
                    style={money === 20000000 ? { backgroundColor: '#C23333', color: 'white' } : {}}
                >
                    <img src={goldIcon} alt="" width={50} className="pr-2" />
                    <span>Phòng vé Gold</span>
                </div>

                <div
                    className="flex items-center justify-center border rounded-3 p-3 mt-4"
                    onClick={() => handleChangeMoney(50000000)}
                    style={money === 50000000 ? { backgroundColor: '#C23333', color: 'white' } : {}}
                >
                    <img src={platinumIcon} alt="" width={50} className="pr-2" />
                    <span>Phòng vé Platinum</span>
                </div>

                <div
                    className="flex items-center justify-center border rounded-3 p-3 mt-4"
                    onClick={() => handleChangeMoney(100000000)}
                    style={money === 100000000 ? { backgroundColor: '#C23333', color: 'white' } : {}}
                >
                    <img src={diamoundIcon} alt="" width={50} className="pr-2" />
                    <span>Phòng vé Diamond</span>
                </div>
            </div>

            <div className="p-4">
                <h1 className="text-center mb-4 font-bold">SỐ TIỀN CẦN NẠP</h1>
                <div className="p-3 border rounded-3 fs-2 bg-slate-200">{moneyS}</div>
                {/* <input
                    type="tel"
                    onChange={handleChangeMoney}
                    value={money}
                    className="w-full border outline-none px-2 py-1 text-3xl"
                /> */}
                <h1 className="text-center mb-4 font-bold mt-4">KÊNH NẠP TIỀN</h1>
                <div className="actions">
                    <div className="mess-title">
                        <img src={imageCSKH} alt="CSKH" width="70px" height="70px" />
                        <div className="desc-recharge">
                            Sau khi tạo yêu cầu nạp tiền, vui lòng liên hệ bộ phận CSKH để được hướng dẫn hoàn tất!
                        </div>
                    </div>
                    <div className="action"></div>
                </div>
                <div className="button">
                    <button
                        className="button-recharge fs-2"
                        onClick={() => {
                            if (checkMoney() === true) {
                                toggle();
                            } else setModal(false);
                        }}
                    >
                        TẠO YÊU CẦU NẠP TIỀN
                    </button>
                </div>
                <Modal isOpen={modal} toggle={toggle} {...args} centered>
                    <div className="modal-home-style">
                        <ModalBody>
                            <div className="swal2-header">
                                <div className="swal2-icon swal2-warning swal2-icon-show">
                                    <div className="swal2-icon-content">!</div>
                                </div>
                                <h2 className="swal2-title">Xác nhận thông tin</h2>
                            </div>
                            <div className="swal2-content">
                                Bạn có đồng ý nạp <span className="text-highlight">{`${formatMoney(money)}`}</span>?
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {hideButton ? (
                                <>
                                    <Button color="primary" size="lg" onClick={RechargeHandler}>
                                        Đồng ý
                                    </Button>
                                    <Button color="danger" size="lg" onClick={toggle}>
                                        Không
                                    </Button>
                                </>
                            ) : (
                                <div className="swal2-content">
                                    Xin đợi khoảng 5s trong thời gian chúng tôi tạo đơn nạp!
                                </div>
                            )}
                        </ModalFooter>
                    </div>
                </Modal>

                <Modal isOpen={modal2} toggle={toggle2} {...args}>
                    <div className="modal-home-style">
                        <ModalBody>
                            <div className="swal2-header">
                                <div className="swal2-icon swal2-success swal2-icon-show">
                                    <div className="swal2-icon-content-check">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </div>
                                </div>
                            </div>
                            <div className="swal2-content">
                                <div className="swal2-content-one">Khởi tạo yêu cầu nạp tiền thành công!</div>
                                <div className="swal2-content-two">
                                    Vui lòng liên hệ bộ phận chăm sóc khách hàng để hoàn tất.
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <a
                                color="primary"
                                size="lg"
                                href="https://direct.lc.chat/15964677/"
                                className="bg-blue-600 text-white p-2 rounded-3 fs-4"
                                target="_blank"
                            >
                                Liên hệ bộ phận chăm sóc khách hàng!
                            </a>

                            <Button color="danger" size="lg" onClick={toggle2}>
                                Đóng
                            </Button>
                        </ModalFooter>
                    </div>
                </Modal>

                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    );
}

export default Recharge;
