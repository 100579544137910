import Home from '../pages/Home';
import My from '../pages/My';
import Order from '../pages/Order/index';
import Login from '../pages/Account/Login';
import Register from '../pages/Account/Register';
import ComfirmOrder from '../pages/Order/ComfirmOrder';
import FinancialDetails from '../pages/My/FinancialDetails';
import History from '../pages/Order/History';
import Settings from '../pages/My/Settings';
import Guide from '../pages/My/Guide';
import Service from '../pages/My/Service';
import EditPassword from '../pages/My/EditPassword';
import EditPwTransaction from '../pages/My/EditPwTransaction';
import MyBank from '../pages/My/MyBank';
import Withdraw from '../pages/My/Withdraw';
import Recharge from '../pages/My/Recharge';
import FormRecharge from '../pages/My/FormRecharge';
import OrderRedirect from '../pages/Order/OrderRedirect';
import NotFound from '../pages/NotFound';
import Support from '../pages/My/Support';
import HistoryRechargeWithdraw from '../pages/My/HistoryRechargeWithdraw';
import InfoUser from '../pages/My/InfoUser';
import { RoomTickets } from '../pages/RoomTicket';
import News from '~/pages/News/News';
import ContentNew from '~/pages/News/ContentNew';
import Artist from '~/pages/Order/Artist';
import ContentShow from '~/pages/Order/ContentShow';
import MoneyHeart from '~/pages/My/MoneyHeart';

// Public Router

let defaultTitle = 'TicketBox';

const publicRoutes = [
    // Trang chủ
    { path: '/', title: 'Trang chủ', component: Home },

    // Tin tức
    { path: '/news', title: 'Tin tức', component: News },
    { path: '/news/:id', title: 'Tin tức', component: ContentNew },
    { path: '/artist/:id', title: 'Nghệ sĩ', component: Artist },
    { path: '/content-show', title: 'Nội dung show', component: ContentShow },

    // Nạp rút tiền
    { path: '/recharge', title: `Nạp tiền | ${defaultTitle}`, component: Recharge },
    { path: '/recharge/:id', title: `Nạp tiền | ${defaultTitle}`, component: FormRecharge },
    { path: '/withdraw', title: `Rút tiền | ${defaultTitle}`, component: Withdraw },
    { path: '/loves', title: `Cặp đôi | ${defaultTitle}`, component: MoneyHeart },

    // Order
    { path: '/support', title: 'Hỗ trợ khách hàng', component: Support },
    { path: '/order/mission', title: 'Nhiệm vụ', component: OrderRedirect },
    { path: '/order/mission/:id_mission', title: 'Nhiệm vụ', component: Order },
    { path: '/order/index', title: 'Lịch sử đặt hàng', component: History },
    { path: '/order/confirm/:id', title: `Comfirm Order | ${defaultTitle}`, component: ComfirmOrder },

    // My
    { path: '/my', title: 'Của tôi', component: My },
    { path: '/my/settings', title: 'Cài đặt', component: Settings },
    { path: '/my/guide', title: 'Hướng dẫn', component: Guide },
    { path: '/my/service', title: 'Dịch vụ', component: Service },
    { path: '/my/edit-password', title: 'Đổi mật khẩu', component: EditPassword },
    { path: '/my/banking', title: 'Liên kết ngân hàng', component: MyBank },
    { path: '/roomtickets', title: 'Cổ phiếu', component: RoomTickets },
    {
        path: '/my/edit-password-transaction',
        title: 'Đổi mật khẩu giao dịch',
        component: EditPwTransaction,
    },
    // {
    //     path: '/my/financial-details',
    //     title: `Financial details | ${defaultTitle}`,
    //     component: FinancialDetails,
    // },
    // Account
    {
        path: '/account/login',
        title: `Đăng nhập ngay | ${defaultTitle}`,
        component: Login,
    },
    { path: '/account/register', title: `Đăng ký ngay | ${defaultTitle}`, component: Register },
    { path: '/history-recharge-withdraw', title: `Lịch sử nạp rút`, component: HistoryRechargeWithdraw },
    { path: '/my/user-info', title: `Thông tin cá nhân`, component: InfoUser },
    { path: '*', title: `NotFound | ${defaultTitle}`, component: NotFound },
];

// Private Router
const privateRoutes = [];

export { publicRoutes, privateRoutes };
