import Header from '../../components/Layout/components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faBookmark, faCalendarTimes, faTicket } from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import audio from '../../assets/audio/hongbao.mp3';
import '../../style/order.css';
import iconMedal from '../../assets/images/icon-medal.png';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import vipTicket from '../../assets/images/vip-ticket.jpg';
import iconVip from '../../assets/images/icon-vip.png';
import { useGetMovieMainQuery, useGetListArtistQuery } from '~/features';
import Navbar from '~/components/Layout/components/Navbar';
import phongVeIcon from '~/assets/images/ticket_icon_active.png';
import hoSoNgheSi from '~/assets/images/ho_so_nghe_si.png';
import iphone14 from '~/assets/images/iphone14promax.png';
import Expired from '~/components/Countdown/Expired';
import Confetti from 'react-confetti';

const domain = process.env.REACT_APP_DOMAIN;
const axios = require('axios').default;

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

function formateT(params) {
    let result = params < 10 ? '0' + params : params;
    return result;
}

function timerJoin2(params) {
    let date = '';
    if (params) {
        date = new Date(params);
    } else {
        date = new Date();
    }
    let years = formateT(date.getFullYear());
    let months = formateT(date.getMonth() + 1);
    let days = formateT(date.getDate());

    let hours = formateT(date.getHours());
    let minutes = formateT(date.getMinutes());
    let seconds = formateT(date.getSeconds());
    return days + '/' + months + '/' + years + ' ' + hours + ':' + minutes + ':' + seconds;
}

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${domain}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function Order(args) {
    const { id_mission } = useParams();
    const newId = useRef(id_mission);
    let [tickets, setTickets] = useState(0);
    let [leftTicket, setLeftTicket] = useState('');
    let [movies, setMovies] = useState();
    let [statusButton, setStatusButton] = useState(false);
    let [textButton, setTextButton] = useState('BÁN NGAY - 00:00:00');
    let [idOrder, setIdOrder] = useState('');
    let [username, setUsername] = useState('');

    let [modal, setModal] = useState(false);
    let [orderVIP, setOrderVIP] = useState();
    let [moneyVIP, setMoneyVIP] = useState();

    const navigate = useNavigate();

    const toggle = () => setModal(!modal);

    const { data: movieMain } = useGetMovieMainQuery();
    const { data: listArtist } = useGetListArtistQuery();

    const playAudio = () => {
        new Audio(audio).play();
    };

    const handleChangeButton = () => {
        setStatusButton(() => true);
        setTimeout(() => {
            setTextButton('ĐANG KẾT NỐI...');
        }, 2000);
        setTimeout(() => {
            setTextButton('TÌM NGƯỜI MUA...');
        }, 4000);
        setTimeout(() => {
            setTextButton('ĐANG ĐỢI PHẢN HỒI...');
        }, 6000);
        setTimeout(() => {
            setTextButton('ĐANG BÁN...');

            // Call API giảm vé
            axios
                .put(
                    `${domain}/api/webapi/reduce-tickets`,
                    { idOrder, username },
                    {
                        headers: {
                            'x-access-token': localStorage.getItem('auth'),
                            'Access-Control-Allow-Origin': '*',
                        },
                    },
                )
                .then(function (response) {
                    let data = response.data.result;
                    if (data.type === false) {
                        toast.warn(data.msg, { theme: 'light' });
                        setStatusButton(() => false);
                        setTextButton('BÁN NGAY - 00:00:00');
                        return;
                    }
                    if (data.status === 'VIP') {
                        toggle();
                        setStatusButton(() => false);
                        setTextButton('BÁN NGAY - 00:00:00');
                        console.log(data.order);
                        setOrderVIP(data.order);
                        setMoneyVIP(data.order.price);
                        return;
                    }
                    if (data.status) {
                        toast.success(data.msg, { theme: 'light' });
                        setStatusButton(() => false);
                        setTextButton('BÁN NGAY - 00:00:00');
                        setTickets((pre) => pre - 1);
                        return;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, 8000);
    };

    useEffect(() => {
        checkToken();
        axios
            .get(`${domain}/api/webapi/list/movies`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                setMovies(data.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios
            .get(`${domain}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                setLeftTicket(data.data[0].amount);
                setIdOrder(data.data[0].don_hang);
                setTickets(data.data[0].tickets);
                setUsername(data.data[0].username);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div className="wrapper p-4 h-[960px]">
            <div className="header_my flex p-2 mb-8" style={{ borderBottom: '1px dashed gray' }}>
                <img src={phongVeIcon} alt="" width={30} />
                <div className="fs-1 font-bold pl-2">Phòng vé</div>
            </div>

            <Link className="relative" to="/content-show" state={{ data: movieMain }}>
                <img src={movieMain?.linkImage} alt="" />
                <span className="yt-open-flash-modal fs-5" data-type="post-detail" data-args='{"id":899}'>
                    Đọc tiếp...
                </span>
            </Link>

            <div className="info-show__main fs-3 mt-4 pb-12" style={{ borderBottom: '1px dashed gray' }}>
                <div className="info-title fs-1 text-sky-700 font-bold">{movieMain?.title}</div>
                <div>Thời gian: {movieMain?.timeShow}</div>
                <div>Địa điểm: {movieMain?.address}</div>
                <div>Giá vé: {movieMain?.price}</div>
                <div>
                    Nghệ sĩ:{' '}
                    {listArtist &&
                        listArtist.map((item, i) => {
                            if (i === listArtist.length - 1) {
                                return (
                                    <Link key={item.id} to={`/artist/${i}`} state={{ data: listArtist[i] }}>
                                        {item.title}
                                    </Link>
                                );
                            } else
                                return (
                                    <Link key={item.id} to={`/artist/${i}`} state={{ data: listArtist[i] }}>
                                        {item.title},{' '}
                                    </Link>
                                );
                        })}
                </div>
                <div className="movie-category">
                    Bạn đang có: {tickets || 0}/{leftTicket || 0} vé
                </div>
            </div>

            <div
                className="link-style flex items-center justify-center py-6"
                style={{ borderBottom: '1px dashed gray' }}
            >
                {tickets ? (
                    <Link
                        onClick={handleChangeButton}
                        aria-current={statusButton ? 'disable' : 'default'}
                        className={statusButton ? 'disable' : 'default'}
                    >
                        {textButton}
                    </Link>
                ) : (
                    <Link to="/recharge">PHÒNG VÉ</Link>
                )}
            </div>

            <div className="ho-so-nghe-si mt-4 flex items-center justify-around">
                {listArtist &&
                    listArtist.map((item, i) => {
                        return (
                            <Link
                                key={item.id}
                                className="w-[27%] flex flex-col items-center justify-center mt-16"
                                to={`/artist/${i}`}
                                state={{ data: listArtist[i] }}
                            >
                                <div className="mb-4 p-1" style={{ border: '1px dashed #d4d4d4' }}>
                                    <img src={item.linkImageArtist} alt="" />
                                </div>
                                <span
                                    className="text-center font-bold name-artist__style"
                                    style={{ textTransform: 'uppercase', fontSize: '12px' }}
                                >
                                    {item.title}
                                </span>
                            </Link>
                        );
                    })}
            </div>

            {/* <div className="movie-recharge mt3">
                <Link to="/roomtickets">THAM GIA MỞ PHÒNG VÉ</Link>
            </div> */}

            <Modal isOpen={modal} toggle={toggle} {...args} centered>
                <Confetti numberOfPieces={150} width={390} height={800} />
                <ModalHeader toggle={toggle}>XIN CHÚC MỪNG</ModalHeader>
                <ModalBody className="modal-VIP">
                    <div className="yt-vip-ticket-popup-wrap relative">
                        <img src={`${domain + orderVIP?.linkImage}`} alt="" />
                        {/* <Expired time={orderVIP?.time_end_vip_ticket} /> */}
                        {/* <div className="ticket-endtime flex">
                            Thời gian hết hạn:
                            <div className="yt-countdown">
                                <strong> {timerJoin2(orderVIP?.time_end_vip_ticket)}</strong>
                            </div>
                        </div> */}
                        <div className="ticket-detail">
                            <div>Trị giá : {formatMoney(orderVIP?.price)}</div>
                            <div className="ticket-value mb-2">
                                Hoa hồng:
                                <strong> 30</strong>%
                            </div>
                            <div className="ticket-commission mb-2">
                                Tổng tiền nhận được: <strong>{formatMoney((moneyVIP * 130) / 100)} VNĐ</strong>
                            </div>
                            <div className="ticket-time mb-2">
                                Thời gian tạo: <strong>{timerJoin2(orderVIP?.create_at)}</strong>
                            </div>
                            <div className="ticket-endtime">
                                Thời gian hết hạn:
                                <strong> {timerJoin2(orderVIP?.time_end_vip_ticket)}</strong>
                            </div>
                        </div>
                        {/* <div className="background">
                            <img src={vipTicket} alt="VIP TICKET" />
                        </div>
                        <div className="info">
                            <img src={iconVip} alt="Icon VIP" className="icon-vip" />
                            <div className="info-content flex flex-col items-center">
                                <div className="heading mt-1">{orderVIP.name_vip_level}</div>
                                <div className="font-bold">Trị giá: {formatMoney(moneyVIP)}VNĐ</div>
                                <div className="ticket-detail">
                                    <img src={iphone14} alt="" style={{ width: '340px', height: '100px' }} />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </ModalBody>
                {/* <div className="button-order-style fs-3 text-center">
                    Vui lòng liên hệ chăm sóc khách hàng để được tư vấn và nhận thưởng!
                </div> */}
            </Modal>

            <Navbar link="/order/mission/" />
        </div>
    );
}

export default Order;
