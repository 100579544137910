import React from 'react';
import bgImg from '~/assets/images/background-my.jpg';
import logo from '~/assets/images/logo.png';
import './RoomTicket.scss';

const Header = ({ data }) => {
    return (
        <div className="relative h-32" style={{ background: `url(${bgImg})` }} id="room-ticket-header">
            <div className="absolute top-0 left-0 z-10 flex h-32">
                <img src={logo} className="rounded-full p-4" alt="" id="logo-room-ticket" />
                <div className="text-white flex flex-col p-4">
                    <span className="text-3xl font-bold">{data.title}</span>
                    <span className="text-2xl">Ngày kết thúc: {data.end}</span>
                </div>
            </div>
        </div>
    );
};

export default Header;
