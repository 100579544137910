import React from 'react';

const ActiveTabLv2 = ({ handleChangeTab2, activeTab2, type, text }) => {
    return (
        <span
            className="text-2xl text-center py-3 w-32 text-blue-700 rounded-sm"
            onClick={() => handleChangeTab2(type)}
            style={{
                borderWidth: activeTab2 === type ? '1px' : '',
                borderStyle: activeTab2 === type ? 'solid' : '',
                borderColor: activeTab2 === type ? '#dee2e6' : '',
                color: activeTab2 === type ? 'red' : 'black',
            }}
        >
            {text}
        </span>
    );
};

export default ActiveTabLv2;
