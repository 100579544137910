import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '~/components/Layout/components/Header';

const domain = process.env.REACT_APP_DOMAIN;
const axios = require('axios').default;

let accessToken = localStorage.getItem('auth');

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${domain}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function InfoUser() {
    const [userBank, setUserBank] = useState();

    useEffect(() => {
        if (accessToken) {
            checkToken();
            axios
                .get(`${domain}/api/webapi/userInfo`, {
                    headers: {
                        'x-access-token': localStorage.getItem('auth'),
                        'Access-Control-Allow-Origin': '*',
                    },
                })
                .then(function (response) {
                    let data = response.data;
                    if (data.status === 'ok') {
                        if (data.userBank) {
                            setUserBank(data.userBank);
                        } else {
                            window.location.href = '/my/banking';
                        }
                    }
                })
                .catch(function (error) {
                    toast.error('Có lỗi xảy ra', { theme: 'light' });
                });
        }
    }, []);

    return (
        <div className="yt-push-nav">
            <Header title="Thông tin cá nhân" param="/my/settings" />

            <div className="push-nav-content">
                <div className="yt-profile-detail-wrap">
                    <div className="divider-with-text">
                        <span className="text">THÔNG TIN CÁ NHÂN</span>
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Họ tên:</td>
                                <td>{userBank?.name_u_bank}</td>
                            </tr>
                            <tr>
                                <td>Số điện thoại:</td>
                                <td>
                                    {String(userBank?.phone).slice(0, 3)}****{String(userBank?.phone).slice(-4)}
                                </td>
                            </tr>
                            <tr>
                                <td>Số căn cước:</td>
                                <td>********{String(userBank?.soCanCuoc).slice(-4)}</td>
                            </tr>
                            <tr>
                                <td>Nơi cấp căn cước:</td>
                                <td>{userBank?.noiCapCanCuoc}</td>
                            </tr>
                            <tr>
                                <td>Ngày cấp căn cước:</td>
                                <td>{userBank?.ngayCapCanCuoc}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="divider-with-text">
                        <span className="text">TÀI KHOẢN NGÂN HÀNG</span>
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Tên ngân hàng:</td>
                                <td>{userBank?.name_bank}</td>
                            </tr>
                            <tr>
                                <td>Số TK ngân hàng:</td>
                                <td>******{String(userBank?.stk_bank).slice(-4)}</td>
                            </tr>
                            <tr>
                                <td>Tên thụ hưởng:</td>
                                <td>{userBank?.name_u_bank}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default InfoUser;
