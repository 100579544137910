import { useEffect, useState } from 'react';
import '../../style/history.css';
import imageEmail from '../../assets/images/image-email.png';
import ActiveTab from '~/components/History/ActiveTab';
import { ActiveTabLv2, HistoryOrder } from '~/components/History';
import { useGetHistoryTicketQuery } from '~/features';
import Navbar from '~/components/Layout/components/Navbar';
import historyIcon from '~/assets/images/history_icon_active.png';
import phongVeIcon from '~/assets/images/phongVe.png';
import quaTangVipIcon from '~/assets/images/quaTangVip.png';

function History({ title }) {
    document.title = title;

    const [activeTab, setActiveTab] = useState('ticket');
    const [activeTab2, setActiveTab2] = useState('pending');
    const { data, isLoading } = useGetHistoryTicketQuery(undefined, { refetchOnMountOrArgChange: true });

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
        handleChangeTab2('pending');
    };

    const handleChangeTab2 = (tab) => {
        setActiveTab2(tab);
    };

    return (
        <div className="history-order px-4">
            <div
                className="header_my flex p-4 mb-8 items-center justify-start"
                style={{ borderBottom: '1px dashed gray' }}
            >
                <img src={historyIcon} alt="" width={22} style={{ height: '22px' }} />
                <div className="fs-2 font-bold pl-4">Lịch sử</div>
            </div>

            <div className="flex">
                <ActiveTab
                    handleChangeTab={handleChangeTab}
                    activeTab={activeTab}
                    text="Phòng vé"
                    type="ticket"
                    image={phongVeIcon}
                />
                <ActiveTab
                    handleChangeTab={handleChangeTab}
                    activeTab={activeTab}
                    text="Quà tặng VIP"
                    type="vipticket"
                    image={quaTangVipIcon}
                />
                {/* <ActiveTab handleChangeTab={handleChangeTab} activeTab={activeTab} text="Cổ phiếu" type="cophieu" /> */}
            </div>
            <div className="flex mt-4">
                <ActiveTabLv2
                    handleChangeTab2={handleChangeTab2}
                    activeTab2={activeTab2}
                    text="Chờ xử lý"
                    type="pending"
                />
                <ActiveTabLv2
                    handleChangeTab2={handleChangeTab2}
                    activeTab2={activeTab2}
                    text="Đã xong"
                    type="success"
                />
                <ActiveTabLv2 handleChangeTab2={handleChangeTab2} activeTab2={activeTab2} text="Thất bại" type="fail" />
            </div>
            <div className="flex flex-col">
                <HistoryOrder isLoading={isLoading} activeTab={activeTab} activeTab2={activeTab2} data={data} />
            </div>

            <Navbar link="/order/index" />
        </div>
    );
}

export default History;
