import { useEffect } from 'react';

function Service() {
    useEffect(() => {
        window.$chatwoot.toggle('open');
    }, []);
    return;
}

//
export default Service;
