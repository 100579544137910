import { useState } from 'react';

import { Loading } from '~/components/utils';
import {
    useGetMovieMainQuery,
    useGetRoomTicketInfoQuery,
    useGetRoomTicketLevelQuery,
    usePostRegisterRoomTicketMutation,
} from '~/features';
import { CardItem, Header, RoomLevel } from '~/components/RoomTicket';
import bgImg from '~/assets/images/bg-image-2.png';
import cskh from '~/assets/images/cskh-avatar.png';

const SavingsMoney = () => {
    const [activeTab, setActiveTab] = useState('vip1');

    const { data: roomTicketLevel, isLoading: isLoadingRoomTicketLevel } = useGetRoomTicketLevelQuery();
    const { data: movieMain, isLoading: isLoadingMovieMain } = useGetMovieMainQuery();
    const { data: roomTicketInfo } = useGetRoomTicketInfoQuery(undefined, { refetchOnMountOrArgChange: true });
    const [registerRoomTicket, { isLoading: isLoadingRegister }] = usePostRegisterRoomTicketMutation();

    const handleSubmit = () => registerRoomTicket({ type: activeTab });

    if (isLoadingRoomTicketLevel || isLoadingMovieMain) return <Loading />;
    return (
        <div>
            <Header data={movieMain} />
            <CardItem data={roomTicketInfo} />
            <h3 className="text-center font-bold text-3xl">LỰA CHỌN GÓI ĐẦU TƯ</h3>
            <RoomLevel setActiveTab={setActiveTab} activeTab={activeTab} data={roomTicketLevel} />
            <h3 className="text-center font-bold text-3xl">KÊNH NẠP TIỀN</h3>
            <div
                style={{ background: `url(${bgImg}) no-repeat` }}
                className="m-4 rounded-2xl flex flex-col justify-center items-center"
            >
                <img src={cskh} className="w-20 my-4" alt="" />
                <p className="text-center px-4 text-white text-2xl mb-4">
                    Sau khi đăng ký đầu tư cổ phiếu, vui lòng liên hệ bộ phần CSKH để được hướng dẫn hoàn tất
                </p>
            </div>
            <div className="w-full flex justify-center">
                <button
                    onClick={handleSubmit}
                    className="py-4 w-2/3 bg-red-500 font-bold rounded-md text-3xl text-white disabled:opacity-20"
                    disabled={isLoadingRegister}
                >
                    Đăng ký
                </button>
            </div>
        </div>
    );
};

export default SavingsMoney;
