import React from 'react';

const ActiveTab = ({ handleChangeTab, activeTab, type, text, image }) => {
    return (
        <span
            className="text-2xl text-center py-3 w-48 text-blue-700 rounded-md flex items-center justify-center flex-col"
            onClick={() => handleChangeTab(type)}
            style={{
                // background: activeTab === type ? '#006997' : '',
                color: activeTab === type ? 'red' : 'black',
            }}
        >
            <img src={image} alt="" width={35} className="pb-2" />
            {text}
        </span>
    );
};

export default ActiveTab;
