import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { roomTicket, roomTicketInfo, roomTicketRegister } from '../api';
import { checkToken, headers } from './coditional';
import { toast } from 'react-toastify';
import { messageUpdate } from '~/utils/toast';

const domain = process.env.REACT_APP_DOMAIN;

export const roomTicketApi = createApi({
    reducerPath: 'roomticketApi',
    tagTypes: ['RoomTicket'],
    baseQuery: fetchBaseQuery({ baseUrl: domain }),
    endpoints: (builder) => ({
        getRoomTicketLevel: builder.query({
            query: () => ({ url: roomTicket, headers: headers() }),
            transformResponse: (res, meta, arg) => {
                checkToken(res);
                return res.data;
            },
        }),
        getRoomTicketInfo: builder.query({
            query: () => ({ url: roomTicketInfo, headers: headers() }),
            transformResponse: (res, meta, arg) => {
                checkToken(res);
                return res.data;
            },
        }),
        postRegisterRoomTicket: builder.mutation({
            query: (params) => ({ url: roomTicketRegister, headers: headers(), method: 'POST', body: params }),
            transformResponse: (res, meta, arg) => {
                checkToken(res);
                messageUpdate({
                    id: 'loading-toast',
                    status: res.status,
                    msg: res.msg,
                });
                return res;
            },
            async onQueryStarted(arg, { dispatch }) {
                toast.loading('Vui lòng chờ', { toastId: 'loading-toast' });
            },
        }),
    }),
});

export const { useGetRoomTicketLevelQuery, useGetRoomTicketInfoQuery, usePostRegisterRoomTicketMutation } =
    roomTicketApi;
